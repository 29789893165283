import HttpInterceptor from './HttpInterceptor';

export default {

  name: 'EmployeeService',

  getAllEmployees() {
    return HttpInterceptor.httpClient().get('employee/all');
  },

  getReadableEmployees() {
    return HttpInterceptor.httpClient().get('employee/facilities/readable');
  },

  getAllOrganizationEmployees() {
    return HttpInterceptor.httpClient().get('employee/organization/all');
  },

  getAllArchivedOrganizationEmployees() {
    return HttpInterceptor.httpClient().get('employee/organization/archived/all');
  },

  getAllOrganizationFacilityEmployees(referenceNumber) {
    return HttpInterceptor.httpClient().get('employee/organization/facility/' + referenceNumber);
  },

  getAllArchivedOrganizationFacilityEmployees(referenceNumber) {
    return HttpInterceptor.httpClient().get('employee/organization/facility/' + referenceNumber + '/archived');
  },

  deleteEmployee(employeeId) {
    return HttpInterceptor.httpClient().delete('employee/' + employeeId + '/delete');
  },

  updateEmployee(employee) {
    return HttpInterceptor.httpClient().put('employee/' + employee.id + '/update', employee);
  },

  deleteImageOfEmployee(employeeId) {
    return HttpInterceptor.httpClient().delete('employee/' + employeeId + '/image');
  },

  addWorkingTime(employeeId, workingTime) {
    return HttpInterceptor.httpClient().put('employee/' + employeeId + '/workingtime/add', workingTime);
  },

  deleteWorkingTime(employeeId, refNr) {
    return HttpInterceptor.httpClient().delete('employee/' + employeeId + '/workingtime/delete/' + refNr);
  },

  updateWorkingTime(employeeId, workingTime) {
    return HttpInterceptor.httpClient().put('employee/' + employeeId + '/workingtime/update', workingTime);
  },

  toggleArchiveState(employeeId) {
    return HttpInterceptor.httpClient().put('employee/' + employeeId + '/archive/toggle', {});
  },
}
