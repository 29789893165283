<template>
  <div v-if="employee">
    <div v-if="kibig">
      <div v-if="raw">
        <p>KiBiG-Id : {{ employee.kibigId }}</p>
        <p>KiBiG-Name : {{ employee.kibigName }}</p>
      </div>
      <div v-else>
        <table class="hover">
          <thead>
          </thead>
          <tbody>
          <tr>
            <td><p>Id</p></td>
            <td><p><span>{{ employee.kibigId }}</span></p></td>
          </tr>
          <tr>
            <td><p>Name</p></td>
            <td><p><span>{{ employee.kibigName }}</span></p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="characteristics">
      <div v-if="raw">
        <p>Erste-Hilfe-Kurs : {{ employee.firstAidCourseDate | moment("DD.MM.YYYY") }}</p>
        <p>Führungszeugnis : {{ employee.certificateOfGoodConductDate | moment("DD.MM.YYYY") }}</p>
        <p>Sicherheit und Gesundheitsschutz : {{ employee.instructionOnSafetyAndHealthProtectionDate | moment("DD.MM.YYYY") }}</p>
        <p>In Elternzeit : {{ HelperService.booleanText(employee.elternzeit) }}</p>
        <p>Erste Masernimpfung : {{ employee.firstMeaslesVaccinationDate | moment("DD.MM.YYYY") }}</p>
        <p>Zweite Masernimpfung : {{ employee.secondMeaslesVaccinationDate | moment("DD.MM.YYYY") }}</p>
      </div>
      <div v-else>
        <table class="hover">
          <tbody>
          <tr>
            <td><p>Erste-Hilfe-Kurs</p></td>
            <td><p>
              <span v-if="employee.firstAidCourseDate">{{ employee.firstAidCourseDate | moment("DD.MM.YYYY") }}</span>
              <span v-else><b style="color: red">Kein Kurs absolviert!</b></span>
            </p></td>
          </tr>
          <tr>
            <td><p>Führungszeugnis</p></td>
            <td><p>
              <span v-if="employee.certificateOfGoodConductDate">
                {{ employee.certificateOfGoodConductDate | moment("DD.MM.YYYY") }}
              </span>
              <span v-else><b style="color: red">Kein Führungszeugnis vorhanden!</b></span>
            </p></td>
          </tr>
          <tr>
            <td><p>Sicherheit und Gesundheitsschutz</p></td>
            <td><p>
              <span v-if="employee.instructionOnSafetyAndHealthProtectionDate">
                {{ employee.instructionOnSafetyAndHealthProtectionDate | moment("DD.MM.YYYY") }}
              </span>
              <span v-else><b style="color: red">Keine Unterweisung vorhanden!</b></span>
            </p></td>
          </tr>
          <tr>
            <td><p>In Elternzeit</p></td>
            <td><p>
              <span>{{ HelperService.booleanText(employee.elternzeit) }}</span>
            </p></td>
          </tr>
          <tr v-if="!employee.exemptionMeaslesVaccination">
            <td><p>Erste Masernimpfung</p></td>
            <td><p>
              <span v-if="employee.firstMeaslesVaccinationDate">
                {{ employee.firstMeaslesVaccinationDate | moment("DD.MM.YYYY") }}
              </span>
              <span v-else><b style="color: red">nicht geimpft!</b></span>
            </p></td>
          </tr>
          <tr v-if="!employee.exemptionSecondMeaslesVaccination">
            <td><p>Zweite Masernimpfung</p></td>
            <td><p>
              <span v-if="employee.secondMeaslesVaccinationDate">
                {{ employee.secondMeaslesVaccinationDate | moment("DD.MM.YYYY") }}
              </span>
              <span v-else><b style="color: red">nicht geimpft!</b></span>
            </p></td>
          </tr>
          </tbody>
        </table>
        <div v-if="employee.exemptionMeaslesVaccination">
          <md-checkbox class="md-primary" v-model="employee.exemptionMeaslesVaccination" disabled>
            Freistellung Masernimpfung
          </md-checkbox>
          <br>
        </div>
        <div v-if="employee.exemptionSecondMeaslesVaccination">
          <md-checkbox class="md-primary" v-model="employee.exemptionSecondMeaslesVaccination" disabled>
            Freistellung 2. Masernimpfung
          </md-checkbox>
          <br>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!enableContractDetails">
        <div v-if="raw">
          <p>Sozialversicherungs-Nr : {{ employee.socialSecurityNumber }}</p>
          <p>Krankenversicherung : {{ employee.healthInsurance }}</p>
          <p>Monatliches Gehalt : {{ employee.monthlySalary | currency }}</p>
        </div>
        <div v-else>
          <table class="hover">
            <thead>
            </thead>
            <tbody>
            <tr>
              <td><p>Einrichtung</p></td>
              <td><p><span>{{ employee.facilityName }}</span></p></td>
            </tr>
            <tr>
              <td><p>Sozialversicherungs-Nr</p></td>
              <td><p><span>{{ employee.socialSecurityNumber }}</span></p></td>
            </tr>
            <tr>
              <td><p>Krankenversicherung</p></td>
              <td><p><span>{{ employee.healthInsurance }}</span></p></td>
            </tr>
            <tr>
              <td><p>Monatliches Gehalt</p></td>
              <td><p><span>{{ employee.monthlySalary | currency }}</span></p></td>
            </tr>
            <tr>
              <td><p>Entgeltgruppe</p></td>
              <td><p><span>{{ employee.entgeltGruppe }}</span></p></td>
            </tr>
            <tr>
              <td><p>Entgeltstufe bei Arbeitsbeginn</p></td>
              <td><p><span>{{ employee.entgeltStufeStart }}</span></p></td>
            </tr>
            <tr>
              <td><p>Gegenw&auml;rtige Entgeltstufe</p></td>
              <td><p><span>{{ employee.currentEntgeltStufe }}</span></p></td>
            </tr>
            <tr>
              <td><p>N&auml;chster Stufenanstieg</p></td>
              <td><p><span>{{ employee.nextStufe  | moment("DD.MM.YYYY") }}</span></p></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div v-if="raw">
          <p>Vertragsbeginn : {{ employee.contractStart | moment("DD.MM.YYYY") }}</p>
          <p>Vertragsende : {{ employee.contractEnd | moment("DD.MM.YYYY") }}</p>
          <p>Befristet bis : {{ employee.limitedUntil | moment("DD.MM.YYYY") }}</p>
        </div>
        <div v-else>
          <table class="hover">
            <thead>
            </thead>
            <tbody>
            <tr>
              <td><p>Vertragsbeginn</p></td>
              <td><p><span>{{ employee.contractStart | moment("DD.MM.YYYY") }}</span></p></td>
            </tr>
            <tr>
              <td><p>Vertragsende</p></td>
              <td><p><span>{{ employee.contractEnd | moment("DD.MM.YYYY") }}</span></p></td>
            </tr>
            <tr>
              <td><p>Befristet bis</p></td>
              <td><p><span>{{ employee.limitedUntil | moment("DD.MM.YYYY") }}</span></p></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperService from "../../services/HelperService";

export default {
  name: 'EmployeeView',

  props: {
    employee: {
      required: true,
    },
    raw: {
      required: false,
    },
    enableContractDetails: {
      required: false,
    },
    kibig: {
      required: false,
    },
    characteristics: {
      required: false,
    },
  },

  computed: {
    HelperService() {
      return HelperService
    },
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

span {
  color: cornflowerblue;
}

table {
  border-collapse: unset;
}

tbody th, tbody td {
  padding: 0;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: transparent;
}
</style>
