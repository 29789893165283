<template>
  <div v-if="employee">
    <div v-if="raw">
      <p>Bereich : {{labels.divisiontype[employee.divisionType]}}</p>
      <p>Arbeitsbereich : {{labels.workingArea[employee.workingArea]}}</p>
      <p v-if="employee.secondDivisionType && employee.secondWorkingArea">2. Bereich : {{labels.divisiontype[employee.secondDivisionType]}}</p>
      <p v-if="employee.secondDivisionType && employee.secondWorkingArea">2. Arbeitsbereich : {{labels.workingArea[employee.secondWorkingArea]}}</p>
      <p>Beruf : {{labels.professionalQualification[employee.professionalQualification]}}</p>
      <p>Qualifikation : {{labels.qualification[employee.qualification]}}</p>
      <p>Typ : {{labels.employeeType[employee.employeeType]}}</p>
      <p>Position : {{labels.jobPosition[employee.jobPosition]}}</p>
    </div>
    <div v-else>
      <table class="hover">
        <thead>
        </thead>
        <tbody>
        <tr>
          <td><p>Bereich</p></td>
          <td><p><span>{{labels.divisiontype[employee.divisionType]}}</span></p></td>
        </tr>
        <tr>
          <td><p>Arbeitsbereich</p></td>
          <td><p><span>{{labels.workingArea[employee.workingArea]}}</span></p></td>
        </tr>
        <tr v-if="employee.secondDivisionType && employee.secondWorkingArea">
          <td><p>2. Bereich</p></td>
          <td><p><span>{{labels.divisiontype[employee.secondDivisionType]}}</span></p></td>
        </tr>
        <tr v-if="employee.secondDivisionType && employee.secondWorkingArea">
          <td><p>2. Arbeitsbereich</p></td>
          <td><p><span>{{labels.workingArea[employee.secondWorkingArea]}}</span></p></td>
        </tr>
        <tr>
          <td><p>Beruf</p></td>
          <td><p><span>{{labels.professionalQualification[employee.professionalQualification]}}</span></p></td>
        </tr>
        <tr>
          <td><p>Qualifikation</p></td>
          <td><p><span>{{labels.qualification[employee.qualification]}}</span></p></td>
        </tr>
        <tr>
          <td><p>Typ</p></td>
          <td><p><span>{{labels.employeeType[employee.employeeType]}}</span></p></td>
        </tr>
        <tr>
          <td><p>Position</p></td>
          <td><p><span>{{labels.jobPosition[employee.jobPosition]}}</span></p></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EmployeeReportingView',

    props: {
      employee: {
        required: true
      },
      raw: {
        required: false
      },
      enableContractDetails: {
        required: false,
      }
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    },
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }

  table {
    border-collapse: unset;
  }

  tbody th, tbody td {
    padding: 0;
  }

  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: transparent;
  }
</style>
