<template>
  <div v-if="employee">
    <md-dialog :md-click-outside-to-close="false" id="editWorkingTimeDialog" :md-active.sync="showDialog"
               @md-closed="closeDialog">

      <md-dialog-title>
        <div class="grid-x grid-padding-x">
          <div v-if="mode === 'view'" class="cell small-7">
            <md-icon>visibility</md-icon>
            <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Arbeitszeiten für&nbsp;&nbsp;<ImageView
              :imageId="employee.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
            <span class="group-title">{{employee.masterdata.fullName}}</span> einsehen
          </div>
          <div v-if="mode === 'update'" class="cell large-9 medium-9">
            <md-icon>edit</md-icon>
            <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Arbeitszeiten für&nbsp;&nbsp;<ImageView
              :imageId="employee.imageId" size="tiny"></ImageView>&nbsp;&nbsp;<span class="group-title">{{employee.masterdata.firstname}}
        {{employee.masterdata.lastname}}</span> bearbeiten
          </div>
          <div v-else-if="mode === 'add'" class="cell large-9 medium-9">
            <md-icon>add</md-icon>
            <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Arbeitszeiten für
            <ImageView :imageId="employee.imageId" size="tiny"></ImageView>&nbsp;<span class="group-title">{{employee.masterdata.firstname}}
        {{employee.masterdata.lastname}}</span> anlegen
          </div>
          <div class="cell large-2 medium-2 small-12" style="margin-top: -5px;">
            <SessionTime></SessionTime>
          </div>
          <div class="cell large-1 medium-1 hide-for-small-only">
          </div>
        </div>
      </md-dialog-title>

      <md-dialog-content id="dialogContentId">

        <form novalidate @submit.prevent="validateWorkingTimes">

          <div v-if="selectedWorkingTime" class="grid-x grid-padding-x">
            <div class="cell small-5">
              <h5 style="font-weight: 300; font-size: large">Bereich:
                <span style="font-weight: 300; font-size: larger">{{labels.divisiontype[employee.divisionType]}}</span>
              </h5>
            </div>

            <div class="cell small-5">
              <h5 style="font-weight: 300; font-size: large">Qualifikation:
                <span style="font-weight: 300; font-size: larger">{{labels.qualification[employee.qualification]}}</span>
              </h5>
            </div>

            <div class="cell small-1">
              <md-button v-if="workingTimesBackup && workingTimesBackup.length > 0 && canWrite"
                         class="md-icon-button md-raised md-accent"
                         @click="onDeleteWorkingTimeEntry"
                         :disabled="sending || employee.archived || !(isAdmin || isHrManager) || !canWrite">
                <md-icon>delete</md-icon>
                <md-tooltip style="font-size: medium;">Arbeitszeit löschen</md-tooltip>
              </md-button>
            </div>

            <div class="cell small-1 text-right">
              <md-button v-if="canWrite" class="md-icon-button md-raised md-primary"
                         @click="onAddWorkingTime(false)"
                         :disabled="!canAddNewWorkingTime || sending || employee.archived || !(isAdmin || isHrManager) || !canWrite">
                <md-icon>playlist_add</md-icon>
                <md-tooltip style="font-size: medium;">Neue Arbeitszeit anlegen</md-tooltip>
              </md-button>
            </div>

            <div v-if="selectedWorkingTime" class="cell small-12">
              <hr>
              <div class="grid-x">
                <div class="cell text-center">
                  <md-button class="md-icon-button md-primary" @click="onNextWorkingTime"
                             :disabled="selectedWorkingTimeIndex + 1 >= employee.workingTimes.length">
                    <md-icon>chevron_left</md-icon>
                    <md-tooltip style="font-size: medium;">Ältere Arbeitszeit</md-tooltip>
                  </md-button>
                  <span class="work-times">Arbeitszeiten&nbsp;{{selectedWorkingTimeIndex + 1}}&nbsp;/&nbsp;{{employee.workingTimes.length}}</span>
                  <md-button class="md-icon-button md-primary" @click="onPrevWorkingTime"
                             :disabled="selectedWorkingTimeIndex <= 0">
                    <md-icon>chevron_right</md-icon>
                    <md-tooltip style="font-size: medium;">Neuere Arbeitszeit</md-tooltip>
                  </md-button>
                </div>
              </div>
              <br>
            </div>

            <div class="cell small-12 medium-6 large-4">
              <md-field :class="getValidationClass('divisionType')">
                <label>Bereich</label>
                <md-select v-model="selectedWorkingTime.divisionType" autocomplete="qualification" :disabled="sending">
                  <md-option v-for="type of labels.divisionTypes" v-bind:value="type" :key="type">
                    {{labels.divisiontype[type]}}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.selectedWorkingTime.divisionType.required">Bereich ist notwendig</span>
              </md-field>
            </div>

            <div class="cell small-12 medium-12 large-8">
              <div class="md-layout">
                <div class="md-layout-item" style="padding-right: 15px;">
                  <md-datepicker id="valid-from-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="validFromDate" md-immediately :disabled="sending"
                                 :class="getValidationClass('validFrom')" :md-disabled-dates="disabledFirstDay"
                                 @md-closed="onCloseValidFromDatePicker">
                    <label>Gültig von</label>
                    <span class="md-error" v-if="$v.selectedWorkingTime.validFrom.$model === 'Invalid date'">Gültig von Datum ist notwendig</span>
                  </md-datepicker>
                </div>
                <div class="md-layout-item" style="padding-left: 15px;">
                  <md-datepicker id="valid-until-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="validUntilDate" md-immediately :disabled="sending"
                                 :class="getValidationClass('validUntil')" :md-disabled-dates="disabledLastDay"
                                 @md-closed="onCloseValidUntilDatePicker" @md-opened="onOpenValidUntilDatePicker">
                    <label>Gültig bis</label>
                    <span class="md-error" v-if="$v.selectedWorkingTime.validUntil.$model === 'Invalid date'">Gültig bis Datum ist notwendig</span>
                  </md-datepicker>
                </div>
              </div>
            </div>

            <div class="cell small-12 medium-6 large-4">
              <md-field :class="getValidationClass('qualification')">
                <label>Qualifikation</label>
                <md-select v-model="selectedWorkingTime.qualification" autocomplete="qualification" :disabled="sending">
                  <md-option v-for="quali of labels.qualifications" v-bind:value="quali" :key="quali">
                    {{labels.qualification[quali]}}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.selectedWorkingTime.qualification.required">Qualifikation ist notwendig</span>
              </md-field>
            </div>

            <div class="cell small-12 medium-12 large-8">
              <md-field :class="getValidationClass('employeeType')">
                <label>Mitarbeiter-Typ</label>
                <md-select v-model="selectedWorkingTime.employeeType" autocomplete="employeeType" :disabled="sending">
                  <md-option v-for="type of labels.employeeTypes" v-bind:value="type" :key="type">
                    {{labels.employeeType[type]}}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.selectedWorkingTime.employeeType.required">Mitarbeiter-Typ ist notwendig</span>
              </md-field>
            </div>

            <div class="cell">
              <br>
              <span style="font-weight: 300; font-size: large">
                <span v-if="selectedWorkingTime.workingAreaOne">Primärer</span>
                <span v-else>Zweiter</span>
                &nbsp;Arbeitsbereich
              </span>
              <md-button v-if="selectedWorkingTime.workingAreaOne" class="md-icon-button md-raised md-primary"
                         @click="onAddWorkingTime(true)"
                         :disabled="!canAddNewWorkingTime || sending || employee.archived || !(isAdmin || isHrManager) || !canWrite" style="margin-top: -12px;">
                <md-icon>work_outline</md-icon>
                <md-tooltip style="font-size: medium;">Arbeitszeit für <b>zweiten Arbeitsbereich</b> anlegen</md-tooltip>
              </md-button>
              <hr>
            </div>

            <div class="cell small-12 medium-6 large-6">
              <md-field :class="getValidationClass('workingArea')">
                <label>Arbeitsbereich</label>
                <md-select v-model="selectedWorkingTime.workingArea" autocomplete="workingArea" :disabled="sending">
                  <md-option v-for="area of labels.workingAreas" v-bind:value="area" :key="area">
                    {{labels.workingArea[area]}}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.selectedWorkingTime.workingArea.required">Arbeitsbereich ist notwendig</span>
              </md-field>
            </div>

            <div class="cell small-12 medium-6 large-6">
              <md-field :class="getValidationClass('facilityRefNr')">
                <label>Einrichtung</label>
                <md-select v-model="selectedWorkingTime.facilityRefNr" :disabled="sending">
                  <md-option v-for="facility of organization.facilities" :value="facility.referenceNumber" :key="facility.referenceNumber">
                    {{ facility.longName }}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.selectedWorkingTime.facilityRefNr.required">Einrichtung ist notwendig</span>
              </md-field>
            </div>

            <div class="cell small-12 medium-6 large-6">
              <md-field :class="getValidationClass('group')">
                <label>Gruppe</label>
                <md-select v-model="selectedWorkingTime.group" autocomplete="group" :disabled="sending">
                  <md-option v-for="group of groupOptions" v-bind:value="group.id" :key="group.id">
                    {{group.name}}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.selectedWorkingTime.group.required">Gruppe ist notwendig</span>
              </md-field>
            </div>

            <div class="cell small-12 medium-6 large-6">
              <md-field :class="getValidationClass('hoursPerWeek')">
                <label>Anzahl Wochenstunden</label>
                <md-input type="number" name="hoursPerWeek" id="hoursPerWeek" autocomplete="hoursPerWeek"
                          v-model="selectedWorkingTime.hoursPerWeek"
                          :disabled="sending"/>
                <span class="md-error" v-if="!$v.selectedWorkingTime.hoursPerWeek.minValue">Anzahl Wochenstunden ist notwendig</span>
              </md-field>
            </div>
          </div>

          <md-progress-bar md-mode="indeterminate" v-if="sending"/>

          <br>
          <md-card-actions>
            <div class="cell text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-plus"></i>&nbsp;&nbsp;&nbsp;Arbeitszeiten anlegen
              </button>
              <button v-if="mode !== 'view'" class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </md-card-actions>

        </form>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteWorkingTimeDialog">
      <div v-if="selectedWorkingTime" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>schedule</md-icon>
          &nbsp;&nbsp;&nbsp;Arbeitszeit für&nbsp;&nbsp;
          <ImageView :imageId="employee.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
          <span class="group-title">{{employee.masterdata.firstname}} {{employee.masterdata.lastname}}</span> löschen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content v-if="selectedWorkingTime.validFrom && selectedWorkingTime.validUntil">Soll die Arbeitszeit
            von
            <span class="group-title">{{selectedWorkingTime.validFrom | moment("DD.MM.YYYY")}}</span> bis
            <span class="group-title">{{selectedWorkingTime.validUntil | moment("DD.MM.YYYY")}}</span><br> für den
            <span v-if="selectedWorkingTime.workingAreaOne">primären Arbeitsbereich</span>
            <span v-else>zweiten Arbeitsbereich</span> <span><b>{{labels.workingArea[selectedWorkingTime.workingArea]}}</b></span>
            wirklich gelöscht werden?
            <div v-if="selectedWorkingTime.workingAreaOne">
              <br>
              <span><b>Hinweis:</b></span> Ein vorhandener 2. Arbeitsbereich für den selben Zeitraum wird dabei auch gelöscht.
            </div>
          </md-content>
          <md-content v-else>Soll diese Arbeitszeit wirklich gelöscht werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="onDeleteWorkingTime" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Arbeitszeit jetzt löschen
          </div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="closeDeleteWorkingTimeDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeDeleteWorkingTimeDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>

</template>

<script>

  import jQuery from 'jquery';
  import EmployeeService from '../../services/EmployeeService'
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import ImageView from '../../components/views/ImageView';
  import SessionTime from '../animations/SessionTime';

  import {validationMixin} from 'vuelidate'
  import Vue from 'vue'

  import {
    required,
    minValue,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'EditWorkingTimesDialog',
    mixins: [validationMixin],

    components: {
      ImageView,
      SessionTime,
    },

    props: {
      employee: {
        required: true
      },
      workingTimes: {
        required: true
      },
      groups: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    watch: {
      workingTimes() {
        this.workingTimesBackup = JSON.parse(JSON.stringify(this.workingTimes));
      },

      showDialog() {
        this.initDatePickerSyncHandler(this.showDialog);
        this.updateGroupOptions();
      },
    },

    data: () => ({
      workingTimeLabels: ['Morgens', 'Vormittags', 'Mittags', 'Nachmittags', 'Abends'],
      selectedWorkingTime: null,
      selectedWorkingTimeIndex: 0,
      selectedWorkingTimePristine: true,
      canAddNewWorkingTime: true,
      showDeleteWorkingTimeDialog: false,
      success: true,
      sending: false,
      errorMsg: '',
      errorCode: '',

      workingTimesBackup: null,

      disabledFirstDay: date => {
        const day = date.getDate();
        return day !== 1;
      },

      disabledLastDay: date => {
        const day = date.getDate();
        const dateString = date.getFullYear() + '-' + Number(date.getMonth() + 1) + '-' + Number(date.getDay() + 1);
        const lastDay = Number(Vue.moment(dateString).endOf('month').format('DD'));
        return day !== lastDay
      },

      invalid: true,
      validFromDate: null,
      validUntilDate: null,

      groupOptions: [],
    }),

    created() {
      this.selectWorkingTime();
      this.formatValidFromDate();
      this.formatValidUntilDate();
    },

    updated() {
      this.selectWorkingTime();

      if (this.showDialog) {
        this.formatValidFromDate();
        this.formatValidUntilDate();
      }

      if (this.employee && this.workingTimes && this.workingTimesBackup === null) {
        this.workingTimesBackup = JSON.parse(JSON.stringify(this.workingTimes));
      }
    },

    validations: {
      selectedWorkingTime: {
        validFrom: {
          required,
        },
        validUntil: {
          required,
        },
        workingArea: {
          required,
        },
        facilityRefNr: {
          required,
        },
        group: {
        },
        hoursPerWeek: {
          required,
          minValue: minValue(1),
        },
        qualification: {
          required,
        },
        divisionType: {
          required,
        },
        employeeType: {
          required,
        },
      },
    },

    methods: {

      initDatePickerSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#valid-from-date-dp').children('input').bind('blur', () => {
              this.validFromDate = jQuery('#valid-from-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#valid-until-date-dp').children('input').bind('blur', () => {
              this.validUntilDate = jQuery('#valid-until-date-dp').children('input').val();
              this.writeBackDates();
            });
          }, 500);
        } else {
          jQuery('#valid-from-date-dp').children('input').unbind('blur');
          jQuery('#valid-until-date-dp').children('input').unbind('blur');
        }
      },

      formatValidFromDate() {
        if (this.validFromDate === null) {
          if (this.selectedWorkingTime && this.selectedWorkingTime.validFrom) {
            this.validFromDate = Vue.moment(this.selectedWorkingTime.validFrom).format('DD.MM.YYYY');
          }
        }
      },

      formatValidUntilDate() {
        if (this.validUntilDate === null) {
          if (this.selectedWorkingTime && this.selectedWorkingTime.validUntil) {
            this.validUntilDate = Vue.moment(this.selectedWorkingTime.validUntil).format('DD.MM.YYYY');
          }
        }
      },

      onCloseValidFromDatePicker() {
        this.updatePristineState();
      },

      onCloseValidUntilDatePicker() {
        this.updatePristineState();
      },

      onOpenValidUntilDatePicker() {
        if ((this.selectedWorkingTime.validFrom && !this.selectedWorkingTime.validUntil) ||
          (this.validFromDate && !this.validUntilDate)) {
          this.selectedWorkingTime.validUntil = this.selectedWorkingTime.validFrom;
          this.validUntilDate = this.validFromDate;
        }
      },

      updateGroupOptions() {
        this.groupOptions = [{id: '', name: '(keine)',}];
        this.groups.forEach(group => this.groupOptions.push(group));
      },

      getTimeString(day) {
        let time = this.selectedWorkingTime.times[day.substr(0, 2).toUpperCase()];
        if (time) {
          let minutes = time.minutes;
          let result = '';
          if (Math.floor(minutes / 60) !== 0) {
            result += Math.floor(minutes / 60) + 'h ';
          }
          if (minutes % 60 !== 0) {
            result += minutes % 60 + 'min';
          }
          return result;
        }
      },

      selectWorkingTime() {
        if (this.employee && this.employee.workingTimes && this.employee.workingTimes.length > this.selectedWorkingTimeIndex) {
          this.selectedWorkingTime = this.employee.workingTimes[this.selectedWorkingTimeIndex];
        } else {
          this.selectedWorkingTime = null;
        }
      },

      updatePristineState() {
        setTimeout(() => {
          if (this.validFromDate && this.validUntilDate) {
            this.selectedWorkingTimePristine = false;
          } else {
            this.selectedWorkingTimePristine = true;
          }
        }, 100)
      },

      resetValidityDates() {
        this.validFromDate = null;
        this.validUntilDate = null;
      },

      onPrevWorkingTime() {
        if (this.selectedWorkingTimeIndex > 0) {
          this.writeBackDates();
          this.selectedWorkingTimeIndex--;
          this.resetValidityDates();
          this.selectWorkingTime();
        }
      },

      onNextWorkingTime() {
        if (this.selectedWorkingTimeIndex < this.employee.workingTimes.length - 1) {
          this.writeBackDates();
          this.selectedWorkingTimeIndex++;
          this.resetValidityDates();
          this.selectWorkingTime();
        }
      },

      onDeleteWorkingTimeEntry() {
        if (this.selectedWorkingTime) {
          jQuery('#editWorkingTimeDialog').attr("style", "display:none");
          this.showDeleteWorkingTimeDialog = true;
        }
      },

      closeDeleteWorkingTimeDialog() {
        jQuery('#editWorkingTimeDialog').attr("style", "display:normal");
        this.showDeleteWorkingTimeDialog = false;
      },

      onDeleteWorkingTime() {
        if (this.selectedWorkingTime.refNr >= 0) {
          this.deleteWorkingTime();
        } else {
          this.employee.workingTimes.splice(this.selectedWorkingTimeIndex, 1);
          this.workingTimesBackup.splice(this.selectedWorkingTimeIndex, 1);
          if (this.selectedWorkingTimeIndex > this.workingTimesBackup.length) {
            this.selectedWorkingTimeIndex -= 1;
          }

          this.canAddNewWorkingTime = true;
          this.closeDeleteWorkingTimeDialog();

          if (this.employee.workingTimes.length <= 0) {
            this.closeDialog();
          }
        }
      },

      deleteWorkingTime() {
        this.closeDeleteWorkingTimeDialog();
        if (this.selectedWorkingTime) {
          this.sending = true;
          this.success = false;
          EmployeeService.deleteWorkingTime(this.employee.id, this.selectedWorkingTime.refNr)
            .then(response => {
              this.success = true;
              this.sending = false;
              this.selectedWorkingTimePristine = true;
              this.$emit("updateWorkingTimeSuccess", response.data);
              this.closeDialog();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Arbeitszeit mit RefNr'
                + this.selectedWorkingTime.refNr + ' für ' + this.employee.masterdata.firstname + ' ' + this.employee.masterdata.lastname);
              this.sending = false;
            })
        }
      },

      onAddWorkingTime(secondWorkArea) {
        let newWorkingTime = {
          refNr: -1,
          validFrom: null,
          validUntil: null,
          hoursPerWeek: 0,
          qualification: '',
          divisionType: '',
          employeeType: '',
          group: '',
          workingArea: '',
          workingAreaOne: true,
        };

        if (secondWorkArea) {
          newWorkingTime = JSON.parse(JSON.stringify(this.selectedWorkingTime));
          newWorkingTime.workingAreaOne = false;
          newWorkingTime.workingArea = '';
          newWorkingTime.group = '';
          newWorkingTime.hoursPerWeek = 0;
          newWorkingTime.refNr= -1;
        }

        this.employee.workingTimes.splice(0, 0, newWorkingTime);
        this.workingTimesBackup.splice(0, 0, newWorkingTime);
        this.selectedWorkingTimeIndex = 0;
        this.canAddNewWorkingTime = false;
        this.validFromDate = null;
        this.validUntilDate = null;
        this.selectWorkingTime();
      },

      addWorkingTime() {
        if (this.selectedWorkingTime) {
          this.sending = true;
          this.success = false;
          EmployeeService.addWorkingTime(this.employee.id, this.selectedWorkingTime)
            .then(response => {
              this.success = true;
              this.sending = false;
              this.selectedWorkingTimePristine = true;
              this.$emit("updateWorkingTimeSuccess", response.data);
              this.closeDialog();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen einer neuen Arbeitszeit für '
                + this.employee.masterdata.fullName);
              this.sending = false;
            })
        }
      },

      updateWorkingTime() {
        if (this.selectedWorkingTime) {
          this.sending = true;
          this.success = false;
          EmployeeService.updateWorkingTime(this.employee.id, this.selectedWorkingTime)
            .then(response => {
              this.success = true;
              this.sending = false;
              this.selectedWorkingTimePristine = true;
              this.$emit("updateWorkingTimeSuccess", response.data);
              this.closeDialog();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern der Arbeitszeit für '
                + this.employee.masterdata.fullName);
              this.sending = false;
            })
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.selectedWorkingTime[fieldName];
        if (field) {
          return {
            'md-invalid': (field.$invalid && field.$dirty) ||
              (field.$model === 'Invalid date' && field.$dirty)
          }
        }
      },

      writeBackDates() {
        if (this.validFromDate) {
          this.selectedWorkingTime.validFrom = Vue.moment(this.validFromDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
          this.employee.workingTimes[this.selectedWorkingTimeIndex].validFrom = this.selectedWorkingTime.validFrom;
        } else {
          this.selectedWorkingTime.validFrom = null;
        }

        if (this.validUntilDate) {
          this.selectedWorkingTime.validUntil = Vue.moment(this.validUntilDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
          this.employee.workingTimes[this.selectedWorkingTimeIndex].validUntil = this.selectedWorkingTime.validUntil;
        } else {
          this.selectedWorkingTime.validUntil = null;
        }
      },

      validateWorkingTimes() {
        this.writeBackDates();
        this.$v.$touch();
        this.invalid = this.$v.$invalid;

        if (!this.selectedWorkingTime.validUntil || !this.selectedWorkingTime.validFrom) {
          jQuery('#dialogContentId').animate({scrollTop: 0}, 'slow');
          this.invalid = true;
        }

        if (!this.invalid) {
          if (this.selectedWorkingTime.refNr < 0) {
            this.addWorkingTime();
          } else {
            this.updateWorkingTime();
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditWorkingTimesDialog");
        this.$v.$reset();
        this.selectedWorkingTimePristine = true;
        this.validFromDate = null;
        this.validUntilDate = null;
        this.selectedWorkingTimeIndex = 0;
        this.canAddNewWorkingTime = true;
        this.workingTimesBackup = null;
      }
    }
    ,

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isHrManager() {
        return this.$store.getters.hrmanager;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      organization() {
        return this.$store.getters.organization;
      },

      labels() {
        return this.$store.getters.labels;
      },
    },
  };
</script>

<style scoped>

  .md-dialog /deep/.md-dialog-container {
    width: 1200px;
    max-width: 95%;
    max-height: 95%;
  }

  .md-icon {
    color: black;
  }

  p {
    margin: 10px;
  }

  span {
    color: cornflowerblue;
  }

  h5 {
    font-weight: 400;
    font-size: x-large;
    color: lightseagreen;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .validity-period {
    text-align: left;
    padding: 5px;
    font-weight: 300;
    font-size: large;
  }

  .validity-description {
    text-align: left;
    padding: 5px;
    font-weight: 300;
    font-size: small;
    color: lightseagreen;
  }

  .work-times {
    font-weight: 400;
    font-size: x-large;
    color: lightseagreen;
    position: relative;
    top: 10px;
  }
</style>