<template>
  <div v-if="alerts">
    <span v-for="alert of preparedAlerts" :key="alert.id" style="white-space: nowrap;">
      <md-icon v-if="alert.state && (['CHILD_DATA', 'EMPLOYEE_DATA'].indexOf(alert.type) >= 0) && (index === 0 || index < 0)" :class="alert.state">list
        <i v-if="alert.alertingDetails.length > 0 && showBadge" class="badge">{{alert.alertingDetails.length}}</i>
      </md-icon>
      <md-icon v-else-if="alert.state && (['BOOKING_TIME'].indexOf(alert.type) >= 0) && (index === 1 || index < 0)" :class="alert.state">access_time
        <i v-if="alert.alertingDetails.length > 0 && showBadge" class="badge">{{alert.alertingDetails.length}}</i>
      </md-icon>
      <md-icon v-else-if="alert.state && (['WORKING_TIME'].indexOf(alert.type) >= 0) && (index === 1 || index < 0)" :class="alert.state">update
        <i v-if="alert.alertingDetails.length > 0 && showBadge" class="badge">{{alert.alertingDetails.length}}</i>
      </md-icon>
      <md-icon v-else-if="alert.state && alert.type.includes('FEE') && (index === 2 || index < 0)" :class="alert.state">euro_symbol
        <i v-if="alert.alertingDetails.length > 0 && showBadge" class="badge">{{alert.alertingDetails.length}}</i>
      </md-icon>
      <img v-else-if="alert.state === 'GREEN' && alert.type.includes('CONTRACT_DATA') && (index === 3 || index < 0)"
           :class="alert.state" style="width: 22px;" src="../../assets/icons/contract-green.svg" alt="green"/>
      <img v-else-if="alert.state === 'YELLOW' && alert.type.includes('CONTRACT_DATA') && (index === 3 || index < 0)"
           :class="alert.state" style="width: 22px;" src="../../assets/icons/contract-yellow.svg" alt="yellow"/>
      <img v-else-if="alert.state === 'RED' && alert.type.includes('CONTRACT_DATA') && (index === 3 || index < 0)"
           :class="alert.state" style="width: 22px;" src="../../assets/icons/contract-red.svg" alt="red"/>
      <img v-else-if="alert.state === 'GREY' && alert.type.includes('CONTRACT_DATA') && (index === 3 || index < 0)"
           :class="alert.state" style="width: 22px;" src="../../assets/icons/contract-grey.svg" alt="grey"/>
      <md-icon v-else-if="alert.state && alert.type.includes('DOCUMENTATION') && (index === 4 || index < 0)" :class="alert.state">library_books
        <i v-if="alert.alertingDetails.length > 0 && showBadge" class="badge">{{alert.alertingDetails.length}}</i>
      </md-icon>
      <md-icon v-else-if="index < 0" :class="alert.state">remove</md-icon>
      <md-tooltip v-if="alert.text && index < 0" md-direction="left" v-html="alert.tooltip"></md-tooltip>
      <span v-if="index < 0">&nbsp;&nbsp;</span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'AlertIcons',

    props: {
      alerts: {
        required: true
      },
      index: {
        required: true
      },
      alert4employee: {
        required: false
      }
    },

    watch: {
      alerts() {
        this.prepareAlerts();
      },
    },

    methods: {
      prepareAlerts() {
        let urgency = 0;

        if (this.alerts) {
          let prepAlerts = [];
          let id = 1;

          for (let type of this.labels.alertingTypes) {
            let found = false;
            for (let alert of this.alerts) {
              if (alert.type === type && !found) {
                let details = '';
                let i = 1;
                for (let detail of alert.alertingDetails) {
                  details += i + ') ' + detail.text + '<br>';
                  i++;
                }
                alert.details = details;
                alert.tooltip = '<b>' + alert.text + '</b><br>' + details;
                prepAlerts.push(alert);
                found = true;
              }
            }
            if (!found) {
              prepAlerts.push({
                id: id++,
                state: '',
                type: '',
                text: '',
                tooltip: '',
                details: '',
                alertingDetails: '',
              })
            }
          }

          this.preparedAlerts = [];
          this.addAlert(prepAlerts, 'CHILD_DATA');
          this.addAlert(prepAlerts, 'BOOKING_TIME');
          this.addAlert(prepAlerts, 'FEE');
          this.addAlert(prepAlerts, 'CONTRACT_DATA');
          this.addAlert(prepAlerts, 'EMPLOYEE_DATA');
          this.addAlert(prepAlerts, 'WORKING_TIME');
          this.addAlert(prepAlerts, 'DOCUMENTATION');

          if (this.preparedAlerts.length === 0) {
            if (this.alert4employee) {
              this.preparedAlerts = [
                {state: 'GREY', type: 'CHILD_DATA', text: '', alertingDetails: []},
                {state: 'GREY', type: 'BOOKING_TIME', text: '', alertingDetails: []},
              ];
            } else {
              this.preparedAlerts = [
                {state: 'GREY', type: 'CHILD_DATA', text: '', alertingDetails: []},
                {state: 'GREY', type: 'BOOKING_TIME', text: '', alertingDetails: []},
                {state: 'GREY', type: 'FEE', text: '', alertingDetails: []},
                {state: 'GREY', type: 'CONTRACT_DATA', text: '', alertingDetails: []},
                {state: 'GREY', type: 'DOCUMENTATION', text: '', alertingDetails: []},
              ];
            }
          }

          for (let alert of this.preparedAlerts) {
            urgency += this.urgencyLevel[alert.state];
          }
        }

        this.$emit("updateAlertUrgency", urgency);
      },

      addAlert(prepAlerts, alert) {
        for (let a of prepAlerts) {
          if (a.type === alert) {
            this.preparedAlerts.push(a);
          }
        }
      },
    },

    mounted() {
      this.prepareAlerts();
    },

    data() {
      return {
        preparedAlerts: [],
        showBadge: false,
        urgencyLevel: {'GREY': 0, 'GREEN': 1, 'YELLOW': 2, 'RED': 3}
      }
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss" scoped>

  .md-tooltip {
    font-size: medium;
    white-space: normal;
    height: auto;
  }

  .GREEN {
    color: green !important;
    animation-duration: 0.33s;
    animation-delay: 1s;
    animation-name: flashAlert;
    animation-iteration-count: 3;
  }

  .YELLOW {
    color: orange !important;
    opacity: 1 !important;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-name: flashAlert;
    animation-iteration-count: 10;
  }

  .RED {
    color: red !important;
    opacity: 1 !important;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-name: flashAlert;
    animation-iteration-count: 20;
  }

  .GREY {
    color: grey !important;
    opacity: 1 !important;
  }

  @keyframes flashAlert {
    0% {
      font-size: 14px !important;
      opacity: 1;
    }

    50% {
      font-size: 24px !important;
      opacity: 0;
    }

    100% {
      font-size: 14px !important;
      opacity: 1;
    }
  }

  .badge {
    width: 19px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 56%;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
    padding: 0;
  }
</style>
