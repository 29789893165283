<template>
  <div>
    <div v-if="kibig">
      <div class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('kibigId')">
            <label>Id</label>
            <md-input name="kibigId" id="kibigId" autocomplete="off"
                      v-model="employee.kibigId" :disabled="sending"/>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('kibigName')">
            <label>Name</label>
            <md-input name="kibigName" id="kibigName" autocomplete="off"
                      v-model="employee.kibigName" :disabled="sending"/>
          </md-field>
        </div>
      </div>
    </div>
    <div v-else-if="characteristics">
      <div class="md-layout">
        <div class="md-layout-item">
          <md-datepicker id="first-aid-course-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="firstAidCourseDate" md-immediately :disabled="sending">
            <label>Erste-Hilfe-Kurs</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item" style="padding-left: 30px;">
          <md-datepicker id="certificate-of-good-conduct-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="certificateOfGoodConductDate" md-immediately :disabled="sending">
            <label>Führungszeugnis</label>
          </md-datepicker>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-datepicker id="instruction-on-safety-and-health-protection-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="instructionOnSafetyAndHealthProtectionDate" md-immediately :disabled="sending">
            <label>Sicherheit und Gesundheitsschutz</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item" style="padding-left: 30px;">
          <md-switch class="md-primary" v-model="employee.elternzeit">
            <div>In Elternzeit</div>
            <md-tooltip>Aktivieren, wenn <b>{{employee.masterdata.fullName}}</b> sich in Elternzeit befindet.</md-tooltip>
          </md-switch>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-checkbox class="md-primary" v-model="employee.exemptionMeaslesVaccination">Freistellung Masernimpfung</md-checkbox>
        </div>
        <div v-if="!employee.exemptionMeaslesVaccination" class="md-layout-item" style="padding-left: 20px;">
          <md-checkbox class="md-primary" v-model="employee.exemptionSecondMeaslesVaccination">Freistellung 2. Masernimpfung</md-checkbox>
        </div>
      </div>
      <br>
      <div v-if="!employee.exemptionMeaslesVaccination" class="md-layout">
        <div class="md-layout-item">
          <md-datepicker id="first-measles-vaccination-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="firstMeaslesVaccinationDate" md-immediately :disabled="sending">
            <label>Erste Masernimpfung</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item" style="padding-left: 30px;">
          <md-datepicker v-if="!employee.exemptionSecondMeaslesVaccination" id="second-measles-vaccination-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="secondMeaslesVaccinationDate" md-immediately :disabled="sending">
            <label>Zweite Masernimpfung</label>
          </md-datepicker>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="employee && !enableContractDetails" class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-6">
          <div v-if="!isSage && availableFacilities && availableFacilities.length > 0">
            <md-field :class="getValidationClass('facilityReference')">
              <label>Einrichtung</label>
              <md-select v-model="employee.facilityReference" :disabled="sending">
                <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber" :key="facility.id">
                  {{ facility.longName }}
                </md-option>
              </md-select>
              <span class="md-error" v-if="!$v.employee.facilityReference.required">Einrichtung ist notwendig</span>
            </md-field>
          </div>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('socialSecurityNumber')">
            <label>Sozialversicherungs-Nr</label>
            <md-input name="socialSecurityNumber" id="socialSecurityNumber" autocomplete="off"
                      v-model="employee.socialSecurityNumber"
                      :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.employee.socialSecurityNumber.required">Sozialversicherungs-Nr ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('healthInsurance')">
            <label>Krankenversicherung</label>
            <md-input name="healthInsurance" id="healthInsurance" autocomplete="off"
                      v-model="employee.healthInsurance"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.employee.healthInsurance.required">Krankenversicherung ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('monthlySalary')">
            <label>Monatliches Gehalt</label>
            <md-input type="number" name="monthlySalary" id="monthlySalary" autocomplete="off"
                      v-model="employee.monthlySalary"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.employee.monthlySalary.required">Monatliches Gehalt ist notwendig</span>
          </md-field>
        </div>

        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('entgeltGruppe')">
            <label>Entgeltgruppe</label>
            <md-input type="number" name="entgeltGruppe" id="entgeltGruppe" autocomplete="off"
                      v-model="employee.entgeltGruppe"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.employee.entgeltGruppe.required">Entgeltgruppe ist notwendig</span>
          </md-field>
        </div>

        <div class="cell large-6 medium-6 small-6">
          <md-field :class="getValidationClass('entgeltStufeStart')">
            <label>Entgeltstufe bei Arbeitsbeginn</label>
            <md-input type="number" name="entgeltStufeStart" id="entgeltStufeStart" autocomplete="off"
                      v-model="employee.entgeltStufeStart"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.employee.entgeltStufeStart.required">Entgeltstufe bei Arbeitsbeginn ist notwendig</span>
          </md-field>
        </div>

        <div class="cell large-6 medium-6 small-6">&nbsp;
        </div>
      </div>
      <div v-if="employee && enableContractDetails">
        <div class="md-layout">
          <div class="md-layout-item" style="padding-right: 15px;">
            <md-datepicker id="contract-start-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="contractStart" md-immediately :disabled="sending"
                           :class="getValidationClass('contractStart')">
              <label>Vertragsbeginn</label>
              <span class="md-error" v-if="$v.employee.contractStart.$model === 'Invalid date'">Vertragsbeginn-Datum ist notwendig</span>
            </md-datepicker>
          </div>
          <div class="md-layout-item" style="padding-left: 15px;">
            <md-datepicker id="contract-end-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="contractEnd" md-immediately :disabled="sending">
              <label>Vertragsende</label>
            </md-datepicker>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item" style="padding-right: 15px;">
            <md-datepicker id="limited-until-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="limitedUntil" md-immediately :disabled="sending">
              <label>Befristet bis</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item" style="padding-left: 15px;">&nbsp;
          </div>
        </div>
        <div class="grid-x grid-padding-y" style="padding-top: 16px;">
          <div class="cell small-12">
            <h5>Vertragsdokument
              <span v-if="employee && employee.signingPerson && employee.signingPerson.fullName"
                    class="hide-for-small-only">für <b>{{ labels.salutation[employee.signingPerson.salutation] }} {{ employee.signingPerson.fullName }}</b></span>
            </h5>
          </div>
        </div>
        <div class="grid-x grid-padding-y">
          <div class="cell large-6 medium-12 small-12">
            <md-chip v-if="employee.documentAvailable" class="md-primary">
              <span>{{ employee.documentFileName }}</span>
            </md-chip>
            <md-chip v-else md-disabled>
              <i>Kein Dokument hinterlegt.</i>
            </md-chip>
          </div>
          <div class="cell large-6 medium-12 small-12">
            <div class="grid-x grid-padding-y">
              <div class="cell small-6">
                <div v-if="employee.documentAvailable">
                  <button disabled class="button secondary" type="button">Dokument hochladen
                  </button>
                </div>
                <div v-else @click="onVdropzoneClick()">
                  <vue-dropzone
                      :disabled="contractDocumentSending || sending || employee.documentAvailable"
                      :options="optionsContractDocument"
                      @vdropzone-error="onVdropzoneError"
                      @vdropzone-mounted="onVdropzoneMounted"
                      @vdropzone-processing="onVdropzoneProcessing"
                      @vdropzone-success="onVdropzoneSuccess"
                      id="contractDocumentFileDropzone"
                      ref="vueDropzoneRef"
                      v-if="optionsContractDocument.url">
                  </vue-dropzone>
                  <md-tooltip>Hier können Sie ein Dokument auswählen und für diesen Vetrag hinterlegen.
                  </md-tooltip>
                </div>
              </div>
              <div class="cell small-5">
                <md-button class="md-icon-button md-raised md-accent"
                           @click="onDeleteContractDocument()"
                           :disabled="!employee.documentAvailable || sending">
                  <md-icon>delete</md-icon>
                  <md-tooltip>Dokument löschen</md-tooltip>
                </md-button>
                <md-button class="md-icon-button md-raised md-third"
                           @click="onDownloadContractDocument()"
                           :disabled="!employee.documentAvailable || sending">
                  <md-icon>archive</md-icon>
                  <md-tooltip>Download Vertragsdokument <b>{{employee.documentFileName}}</b></md-tooltip>
                </md-button>
              </div>
              <div class="cell small-1">
                <span v-if="contractDocumentSending"><vue-simple-spinner></vue-simple-spinner></span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteContractDocumentDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Vertragsdokument löschen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll das Vertragsdokument <span class="title"><b>{{ employee.documentFileName }}</b></span>
            wirklich gelöscht werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button :disabled="sending" @click="deleteContractDocument()" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Vertragsdokument jetzt löschen
          </button>
          <button :disabled="sending" @click="showDeleteContractDocumentDialog = false" class="button success"
                  style="margin-left: 1rem;" type="button">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button @click="showDeleteContractDocumentDialog = false" class="close-button" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate';
  import Vue from 'vue';
  import Spinner from 'vue-simple-spinner';
  import {
    required,
  } from 'vuelidate/lib/validators';
  import vueDropzone from "vue2-dropzone";
  import HttpInterceptor from "@/services/HttpInterceptor";
  import HttpErrorHandler from "@/services/HttpErrorHandler";
  import BinaryService from "@/services/BinaryService";

  export default {
    name: 'EmployeeForm',
    components: {vueDropzone, 'vue-simple-spinner': Spinner},
    mixins: [validationMixin],

    props: {
      employee: {
        required: true,
      },
      availableFacilities: {
        required: false,
      },
      sending: {
        required: true,
      },
      enableContractDetails: {
        required: false,
      },
      kibig: {
        required: false
      },
      characteristics: {
        required: false
      },
    },

    created() {
      this.buildUploadUrl();
      this.formatDates();
      this.initDatePickerSyncHandler(true);
    },

    beforeDestroy() {
      this.initDatePickerSyncHandler(false);
      this.cleanDownloads();
    },

    data: () => ({
      invalid: true,
      contractStart: '',
      contractEnd: '',
      limitedUntil: '',
      certificateOfGoodConductDate: '',
      instructionOnSafetyAndHealthProtectionDate: '',
      firstAidCourseDate: '',
      firstMeaslesVaccinationDate: '',
      secondMeaslesVaccinationDate: '',

      contractDocumentSending: false,

      downloadUrl: null,
      downloadLink: null,
      downloadBlob: null,

      optionsContractDocument: {
        url: '',
        maxFilesize: 20.0, // 20480 KB
        headers: {
          'accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
        },
        createImageThumbnails: false,
        thumbnailWidth: 250,
        thumbnailHeight: 50,
        previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
        dictDefaultMessage: 'Dokument hochladen',
        acceptedFiles: '',
      },

      uploadFileName: null,
      initialContractDocumentLoadSuccess: false,
      initialContractDocumentLoadFailure: false,

      showDeleteContractDocumentDialog: false,
    }),

    validations: {
      employee: {
        socialSecurityNumber: {
          required,
        },
        facilityReference: {
          required,
        },
        healthInsurance: {
          required,
        },
        monthlySalary: {
          required,
        },
        contractStart: {
          required,
        },
        entgeltGruppe: {
          required: false
        },
        entgeltStufeStart: {
          required: false
        }
      },
    },

    methods: {
      
      initDatePickerSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#first-aid-course-date-dp').children('input').bind('blur', () => {
              this.firstAidCourseDate = jQuery('#first-aid-course-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#certificate-of-good-conduct-date-dp').children('input').bind('blur', () => {
              this.certificateOfGoodConductDate = jQuery('#certificate-of-good-conduct-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#instruction-on-safety-and-health-protection-date-dp').children('input').bind('blur', () => {
              this.instructionOnSafetyAndHealthProtectionDate = jQuery('#instruction-on-safety-and-health-protection-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#first-measles-vaccination-date-dp').children('input').bind('blur', () => {
              this.firstMeaslesVaccinationDate = jQuery('#first-measles-vaccination-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#second-measles-vaccination-date-dp').children('input').bind('blur', () => {
              this.secondMeaslesVaccinationDate = jQuery('#second-measles-vaccination-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#contract-start-dp').children('input').bind('blur', () => {
              this.contractStart = jQuery('#contract-start-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#contract-end-dp').children('input').bind('blur', () => {
              this.contractEnd = jQuery('#contract-end-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#limited-until-dp').children('input').bind('blur', () => {
              this.limitedUntil = jQuery('#limited-until-dp').children('input').val();
              this.writeBackDates();
            });
          }, 500);
        } else {
          jQuery('#first-aid-course-date-dp').children('input').unbind('blur');
          jQuery('#certificate-of-good-conduct-date-dp').children('input').unbind('blur');
          jQuery('#instruction-on-safety-and-health-protection-date-dp').children('input').unbind('blur');
          jQuery('#first-measles-vaccination-date-dp').children('input').unbind('blur');
          jQuery('#second-measles-vaccination-date-dp').children('input').unbind('blur');
          jQuery('#contract-start-dp').children('input').unbind('blur');
          jQuery('#contract-end-dp').children('input').unbind('blur');
          jQuery('#limited-until-dp').children('input').unbind('blur');
        }
      },

      updateDatesFromDatePickers() {
        this.firstAidCourseDate = jQuery('#first-aid-course-date-dp').children('input').val();
        this.certificateOfGoodConductDate = jQuery('#certificate-of-good-conduct-date-dp').children('input').val();
        this.instructionOnSafetyAndHealthProtectionDate = jQuery('#instruction-on-safety-and-health-protection-date-dp').children('input').val();
        this.firstMeaslesVaccinationDate = jQuery('#first-measles-vaccination-date-dp').children('input').val();
        this.secondMeaslesVaccinationDate = jQuery('#second-measles-vaccination-date-dp').children('input').val();
        this.contractStart = jQuery('#contract-start-dp').children('input').val();
        this.contractEnd = jQuery('#contract-end-dp').children('input').val();
        this.limitedUntil = jQuery('#limited-until-dp').children('input').val();
      },

      formatDates() {
        if (this.employee.contractStart) {
          this.contractStart = Vue.moment(this.employee.contractStart).format('DD.MM.YYYY');
        }
        if (this.employee.contractEnd) {
          this.contractEnd = Vue.moment(this.employee.contractEnd).format('DD.MM.YYYY');
        }
        if (this.employee.limitedUntil) {
          this.limitedUntil = Vue.moment(this.employee.limitedUntil).format('DD.MM.YYYY');
        }
        if (this.employee.certificateOfGoodConductDate) {
          this.certificateOfGoodConductDate = Vue.moment(this.employee.certificateOfGoodConductDate).format('DD.MM.YYYY');
        }
        if (this.employee.instructionOnSafetyAndHealthProtectionDate) {
          this.instructionOnSafetyAndHealthProtectionDate = Vue.moment(this.employee.instructionOnSafetyAndHealthProtectionDate).format('DD.MM.YYYY');
        }
        if (this.employee.firstAidCourseDate) {
          this.firstAidCourseDate = Vue.moment(this.employee.firstAidCourseDate).format('DD.MM.YYYY');
        }
        if (this.employee.firstMeaslesVaccinationDate) {
          this.firstMeaslesVaccinationDate = Vue.moment(this.employee.firstMeaslesVaccinationDate).format('DD.MM.YYYY');
        }
        if (this.employee.secondMeaslesVaccinationDate) {
          this.secondMeaslesVaccinationDate = Vue.moment(this.employee.secondMeaslesVaccinationDate).format('DD.MM.YYYY');
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.employee[fieldName];
        if (field) {
          return {
            'md-invalid': (field.$invalid && field.$dirty)
          }
        }
      },

      writeBackDates() {
        if (this.contractStart !== '' && this.contractStart != null) {
          this.employee.contractStart = Vue.moment(this.contractStart, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.contractStart = null;
        }

        if (this.contractEnd !== '' && this.contractEnd != null) {
          this.employee.contractEnd = Vue.moment(this.contractEnd, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.contractEnd = null;
        }

        if (this.limitedUntil !== '' && this.limitedUntil != null) {
          this.employee.limitedUntil = Vue.moment(this.limitedUntil, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.limitedUntil = null;
        }

        if (this.certificateOfGoodConductDate !== '' && this.certificateOfGoodConductDate != null) {
          this.employee.certificateOfGoodConductDate = Vue.moment(this.certificateOfGoodConductDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.certificateOfGoodConductDate = null;
        }

        if (this.instructionOnSafetyAndHealthProtectionDate !== '' && this.instructionOnSafetyAndHealthProtectionDate != null) {
          this.employee.instructionOnSafetyAndHealthProtectionDate = Vue.moment(this.instructionOnSafetyAndHealthProtectionDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.instructionOnSafetyAndHealthProtectionDate = null;
        }

        if (this.firstAidCourseDate !== '' && this.firstAidCourseDate != null) {
          this.employee.firstAidCourseDate = Vue.moment(this.firstAidCourseDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.firstAidCourseDate = null;
        }

        if (this.firstMeaslesVaccinationDate !== '' && this.firstMeaslesVaccinationDate != null) {
          this.employee.firstMeaslesVaccinationDate = Vue.moment(this.firstMeaslesVaccinationDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.firstMeaslesVaccinationDate = null;
        }

        if (this.secondMeaslesVaccinationDate !== '' && this.secondMeaslesVaccinationDate != null) {
          this.employee.secondMeaslesVaccinationDate = Vue.moment(this.secondMeaslesVaccinationDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.employee.secondMeaslesVaccinationDate = null;
        }
      },

      validateEmployee() {
        this.updateDatesFromDatePickers();
        this.writeBackDates();

        this.$v.$touch();
        this.invalid = this.$v.$invalid;

        if (this.employee.contractStart === 'Invalid date') {
          this.invalid = true;
        }
      },

      buildUploadUrl() {
        this.optionsContractDocument.url = '';
        setTimeout(() => this.optionsContractDocument.url = this.$store.getters.serverUrl + 'binary/employeecontract/upload/employee/' + this.employee.id, 100);
      },

      onVdropzoneMounted() {
        jQuery('#contractDocumentFileDropzone').removeClass().addClass('button select-contract-document-button');
      },

      onVdropzoneProcessing(file) {
        HttpInterceptor.doRefreshToken();
        this.optionsContractDocument.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
        this.contractDocumentSending = file.processing;
        this.uploadFileName = file.upload.filename;
        this.initialContractDocumentLoadSuccess = false;
        this.initialContractDocumentLoadFailure = false;
      },

      onVdropzoneClick() {
        HttpInterceptor.doRefreshToken();
        this.optionsContractDocument.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
      },

      onVdropzoneError(file, message) {
        this.initialContractDocumentLoadFailure = true;
        let errMsg = 'Fehler beim Lesen der Datei: <b>' + file.upload.filename + '</b><br>';
        if (message.status) {
          errMsg += '<br><b>' + message.status + '</b>';
        }
        if (message.error) {
          errMsg += ' ' + message.error;
        }
        if (message.message) {
          errMsg += '<br>' + message.message;
        }

        this.contractDocumentSending = false;
        HttpErrorHandler.handleError(null, this, errMsg);

        if (message.error === 'invalid_token') {
          HttpInterceptor.forceLogout();
        }
      },

      onVdropzoneSuccess(file, response) {
        this.contractDocumentSending = false;
        this.updateContractDocumentData(response);
        this.$store.commit('successMsg', 'Die Datei <b>' + file.upload.filename + '</b> wurde erfolgreich eingelesen.');
        this.initialContractDocumentLoadSuccess = true;
      },

      updateContractDocumentData(employee) {
        this.employee.documentAvailable = employee.documentAvailable;
        this.employee.documentFileName = employee.documentFileName;
        this.employee.documentId = employee.documentId;
        this.$emit('updateEmployeeContractDocument', this.employee);
      },

      onDeleteContractDocument() {
        if (this.employee) {
          this.showDeleteContractDocumentDialog = true;
        }
      },

      deleteContractDocument() {
        if (this.employee) {
          this.contractDocumentSending = true;
          BinaryService.deleteEmployeeContractDocument(this.employee.id)
              .then((response) => {
                this.updateContractDocumentData(response.data);
                this.contractDocumentSending = false;
                this.showDeleteContractDocumentDialog = false;
                this.$store.commit('successMsg', 'Das Vertragsdokument für Mitarbeiter <b>' + this.employee.masterdata.fullName + '</b> wurde erfolgreich gelöscht.');
              })
              .catch(e => {
                HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Vertragsdokument für Mitarbeiter <b>' + this.employee.masterdata.fullName + '</b>');
              })
        }
      },

      onDownloadContractDocument() {
        if (this.employee) {
          this.downloadContractDocument();
        }
      },

      downloadContractDocument() {
        if (this.employee) {
          BinaryService.downloadEmployeeContractDocument(this.employee.documentId)
              .then((response) => {

                setTimeout(() => {
                  this.cleanDownloads();
                  this.downloadBlob = new Blob([response.data], {type: 'application/octet-stream'});
                  this.downloadUrl = window.URL.createObjectURL(this.downloadBlob);
                  this.downloadLink = document.createElement('a');
                  const id = this.employee.documentFileName;
                  this.downloadLink.href = this.downloadUrl;
                  this.downloadLink.setAttribute('download', id);
                  this.downloadLink.setAttribute('id', id);
                  document.body.appendChild(this.downloadLink);
                  this.downloadLink.click();
                }, 250);
                this.$store.commit('successMsg', 'Das Vertragsdokument für Mitarbeiter <b>' + this.employee.masterdata.fullName + '</b> wurde erfolgreich heruntergeladen.');
              })
              .catch(e => {
                HttpErrorHandler.handleError(e, this, 'Fehler beim Herunterladen des Vertragsdokument für Mitarbeiter <b>' + this.employee.masterdata.fullName + '</b>');
              })
        }
      },

      cleanDownloads() {
        if (this.downloadUrl) {
          this.downloadBlob = null;
          document.body.removeChild(this.downloadLink);
          this.downloadLink = null;
        }
      },
    },

    computed: {
      isSage() {
        return this.$store.getters.sage
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style>
  .md-checkbox {
    margin: 5px !important;
  }
  h5 {
    font-weight: 400;
    color: lightseagreen;
  }
</style>
