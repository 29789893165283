<template>
  <div v-if="bookingSlot">
    <div class="slim" :style="slimStyle">
      <table>
        <tbody>
        <tr v-for="(slot, index) of bookingSlotLabels[bookingSlot]" v-if="index < bookingSlotLabels[bookingSlot].length - 1" :key="index">
          <td v-if="isBookedAndAssigned(slot)" @click="onToggleSlot(index)" :class="divisionType">
            <div :class="coreTime(slot, index)">{{slot}} - {{bookingSlotLabels[bookingSlot][index + 1]}}</div>
            <md-tooltip md-direction="top" style="font-size: medium;">
              Gebucht <b>{{day}} {{bookingSlot}}</b>
              von <b>{{slot}}</b> bis <b>{{bookingSlotLabels[bookingSlot][index + 1]}}</b>
            </md-tooltip>
          </td>
          <td v-else-if="isBooked(slot)" @click="onToggleSlot(index)" :class="divisionType">
            <div :class="coreTime(slot, index)">{{slot}} - {{bookingSlotLabels[bookingSlot][index + 1]}}</div>
            <md-tooltip md-direction="top" style="font-size: medium;">
              Gebucht <b>{{day}} {{bookingSlot}}</b>
              von <b>{{slot}}</b> bis <b>{{bookingSlotLabels[bookingSlot][index + 1]}}</b>
            </md-tooltip>
          </td>
          <td v-if="!isBooked(slot)" @click="onToggleSlot(index)" class="isBooked-false">
            <div :class="coreTime(slot, index)">{{slot}}</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'BookingSlotView',

    props: {
      bookingSlot: {
        required: true
      },
      day: {
        required: true
      },
      times: {
        required: true
      },
      slimStyle: {
        required: true
      },
      editMode: {
        required: false
      },
      showCoreTime: {
        required: true
      },
      divisionType: {
        required: false
      },
      facility: {
        required: false
      },
    },

    mounted() {
      this.updateBookingSlotLabel();
    },

    data: () => ({
      bookingSlotLabels: {
        Morgens: ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00'],
        Vormittags: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00'],
        Mittags: ['12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00'],
        Nachmittags: ['15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00'],
        Abends: ['18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00']
      },
      bookingSlotLabelsInterval: {
        Morgens: ['06:00', '09:00'],
        Vormittags: ['09:00', '12:00'],
        Mittags: ['12:00', '15:00'],
        Nachmittags: ['15:00', '18:00'],
        Abends: ['18:00', '21:00'],
      }
    }),

    methods: {

      updateBookingSlotLabel() {

        if (this.facility && this.facility.facilitySettings &&
            this.facility.facilitySettings.bookingTimesStart && this.facility.facilitySettings.bookingTimesEnd) {
          let slots = [];

          let interval = this.bookingSlotLabelsInterval[this.bookingSlot];
          let start = Vue.moment(this.facility.facilitySettings.bookingTimesStart, 'HH:mm');
          while (Vue.moment(start).isSameOrBefore(Vue.moment(this.facility.facilitySettings.bookingTimesEnd, 'HH:mm'))) {
            if (Vue.moment(start).isSameOrAfter(Vue.moment(interval[0], 'HH:mm')) &&
              Vue.moment(start).isSameOrBefore(Vue.moment(interval[1], 'HH:mm'))) {
              slots.push(Vue.moment(start).format('HH:mm'));
            }
            start = Vue.moment(start).add(this.facility.facilitySettings.timeStepMinutesTimes15 * 15, 'minutes');
          }

          this.bookingSlotLabels[this.bookingSlot] = slots;
        }
      },

      onToggleSlot(index) {

        if (this.editMode) {
          let fromSlot = this.bookingSlotLabels[this.bookingSlot][index];
          let untilSlot = this.bookingSlotLabels[this.bookingSlot][index + 1];

          fromSlot += ':00';
          untilSlot += ':00';



          if (this.dayTime().from === fromSlot && this.dayTime().until === untilSlot) {
            this.dayTime().from = '00:00:00';
            this.dayTime().until = '00:00:00';
            this.dayTime().minutes = 0;
          } else if (this.dayTime().from === '00:00:00' && this.dayTime().until === '00:00:00') {
            this.dayTime().from = fromSlot;
            this.dayTime().until = untilSlot;
          } else if (this.dayTime().from > fromSlot && this.dayTime().until > untilSlot) {
            this.dayTime().from = fromSlot;
          } else if (this.dayTime().from < fromSlot && this.dayTime().until >= untilSlot) {
            this.dayTime().from = fromSlot;
            this.dayTime().until = untilSlot;
          } else if (this.dayTime().from < fromSlot && this.dayTime().until < untilSlot) {
            this.dayTime().until = untilSlot;
          }

          this.dayTime().minutes = this.calcMinutes(this.dayTime().from, this.dayTime().until);
          this.dayTime().assigned = this.dayTime().minutes > 0;

          this.$emit('changeBookingTime');
        }
      },

      calcMinutes(from, until) {
        return Vue.moment(until, 'HH:mm:ss').diff(Vue.moment(from, 'HH:mm:ss'), 'minutes');
      },

      isBooked(slot) {
        slot += ':00';
        if (this.dayTime()) {
          return this.dayTime().from <= slot && this.dayTime().until > slot;
        } else {
          return false;
        }
      },

      isBookedAndAssigned(slot) {
        return this.isBooked(slot) && this.dayTime().assigned;
      },

      dayTime() {
        return this.times[this.day.toString().substr(0, 2).toUpperCase()];
      },

      coreTime(slot, index) {
        let isInCoreTime = Vue.moment(slot, 'hh:mm').isSameOrAfter(Vue.moment(this.facility.coreTimeStart, 'hh:mm:ss')) &&
          Vue.moment(this.bookingSlotLabels[this.bookingSlot][index + 1], 'hh:mm').isSameOrBefore(Vue.moment(this.facility.coreTimeEnd, 'hh:mm:ss'));
        return {
          'crosshatch': isInCoreTime && this.showCoreTime
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

  table {
    border-collapse: separate;
    empty-cells: hide;
    width: 100%;
    margin: 0;
    border-spacing: 1px;
  }

  .slim table tbody td {
    border: 0;
    padding: 0;
    margin: 0;
//    font-size: 10px;
//    line-height: 10px;
  }

  td:hover {
    cursor: pointer;
  }

  hr {
    margin-top: 3px;
    margin-bottom: 0px;
  }

  table tbody tr:nth-child(even) {
    background: rgba(255, 193, 7, 0.4);
  }

  table tbody tr:nth-child(odd) {
    background: rgba(255, 193, 7, 0.4);
  }

  .isBooked-false {
    background: white;
    color: gray;
  }

  .isBooked-true {
    background: lightcyan;
    color: darkcyan;
  }

  .isAssigned-true {
    background: palegreen;
    color: darkgreen;
  }

  .KRIPPE {
    color: black;
    background: lightpink;
  }

  .KINDERGARTEN {
    color: black;
    background: lightskyblue;
  }

  .HORT {
    color: black;
    background: lightgreen;
  }

  .crosshatch {
    // background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPgogIDxyZWN0IHdpZHRoPSc4JyBoZWlnaHQ9JzgnIGZpbGw9JyNmZmYnLz4KICA8cGF0aCBkPSdNMCAwTDggOFpNOCAwTDAgOFonIHN0cm9rZS13aWR0aD0nMC41JyBzdHJva2U9JyNhYWEnLz4KPC9zdmc+Cg==");
    // background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzEnLz4KPC9zdmc+Cg==");
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB3aWR0aD0nNicgaGVpZ2h0PSc2Jz4KICA8cmVjdCB3aWR0aD0nNicgaGVpZ2h0PSc2JyBmaWxsPScjZWVlZWVlJy8+CiAgPGcgaWQ9J2MnPgogICAgPHJlY3Qgd2lkdGg9JzMnIGhlaWdodD0nMycgZmlsbD0nI2U2ZTZlNicvPgogICAgPHJlY3QgeT0nMScgd2lkdGg9JzMnIGhlaWdodD0nMicgZmlsbD0nI2Q4ZDhkOCcvPgogIDwvZz4KICA8dXNlIHhsaW5rOmhyZWY9JyNjJyB4PSczJyB5PSczJy8+Cjwvc3ZnPg==");
    background-repeat: repeat;
    opacity: 0.5;
    width: 100%;
    color: black;
  }
</style>
