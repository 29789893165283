<template>
  <div v-if="employee" class="grid-x grid-padding-x">
      <div class="cell large-6 medium-6 small-6">
        <md-field :class="getValidationClass('divisiontype')">
          <label>Bereich</label>
          <md-select v-model="employee.divisionType" autocomplete="off"
                     :disabled="sending">
            <md-option v-for="divisiontype of labels.divisionTypes" :value="divisiontype" :key="divisiontype">
              {{labels.divisiontype[divisiontype]}}
            </md-option>
          </md-select>
          <span class="md-error" v-if="!$v.employee.divisionType.required">Bereich ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-6">
        <md-field :class="getValidationClass('workingArea')">
          <label>Arbeitsbereich</label>
          <md-select v-model="employee.workingArea" autocomplete="off"
                     :disabled="sending">
            <md-option v-for="workingArea of labels.workingAreas" :value="workingArea" :key="workingArea">
              {{labels.workingArea[workingArea]}}
            </md-option>
          </md-select>
          <span class="md-error" v-if="!$v.employee.workingArea.required">Arbeitsbereich ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-6" v-if="employee.secondDivisionType && employee.secondWorkingArea">
        <md-field>
          <label>2. Bereich</label>
          <md-select v-model="employee.secondDivisionType" autocomplete="off" :disabled="true">
            <md-option v-for="divisiontype of labels.divisionTypes" :value="divisiontype" :key="divisiontype">
              {{labels.divisiontype[divisiontype]}}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-6" v-if="employee.secondDivisionType && employee.secondWorkingArea">
        <md-field>
          <label>2. Arbeitsbereich</label>
          <md-select v-model="employee.secondWorkingArea" autocomplete="off" :disabled="true">
            <md-option v-for="workingArea of labels.workingAreas" :value="workingArea" :key="workingArea">
              {{labels.workingArea[workingArea]}}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <div class="cell large-12 medium-12 small-12">
        <md-field :class="getValidationClass('professionalQualification')">
          <label>Beruf</label>
          <md-select v-model="employee.professionalQualification" autocomplete="off"
                     :disabled="sending"
                     @md-opened="onOpenSelect">
            <md-option v-for="professionalQualification of labels.professionalQualifications"
                       :value="professionalQualification" :key="professionalQualification">
              {{labels.professionalQualification[professionalQualification]}}
            </md-option>
          </md-select>
          <span class="md-error" v-if="!$v.employee.professionalQualification.required">Beruf ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-12 medium-12 small-12">
        <md-field :class="getValidationClass('qualification')">
          <label>Qualifikation</label>
          <md-select v-model="employee.qualification" autocomplete="off"
                     :disabled="sending">
            <md-option v-for="qualification of labels.qualifications" :value="qualification" :key="qualification">
              {{labels.qualification[qualification]}}
            </md-option>
          </md-select>
          <span class="md-error" v-if="!$v.employee.qualification.required">Qualifikation ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-12 medium-12 small-12">
        <md-field :class="getValidationClass('employeeType')">
          <label>Typ</label>
          <md-select v-model="employee.employeeType" autocomplete="off"
                     :disabled="sending"
                     @md-opened="onOpenSelect">
            <md-option v-for="employeeType of labels.employeeTypes" :value="employeeType" :key="employeeType">
              {{labels.employeeType[employeeType]}}
            </md-option>
          </md-select>
          <span class="md-error" v-if="!$v.employee.employeeType.required">Arbeitsbereich ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-12 medium-12 small-12">
        <md-field :class="getValidationClass('jobPosition')">
          <label>Position</label>
          <md-select v-model="employee.jobPosition" autocomplete="off"
                     :disabled="sending"
                     @md-opened="onOpenSelect">
            <md-option v-for="jobPosition of labels.jobPositions" :value="jobPosition" :key="jobPosition">
              {{labels.jobPosition[jobPosition]}}
            </md-option>
          </md-select>
          <span class="md-error" v-if="!$v.employee.jobPosition.required">Position ist notwendig</span>
        </md-field>
      </div>
    </div>
</template>

<script>

  import {validationMixin} from 'vuelidate'
  import jQuery from 'jquery';

  import {
    required,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'EmployeeReportingFrom',
    mixins: [validationMixin],

    props: {
      employee: {
        required: true,
      },
      sending: {
        required: true,
      },
      enableContractDetails: {
        required: false,
      }
    },

    created() {
    },

    data: () => ({
      invalid: true,
    }),

    validations: {
      employee: {
        divisionType: {
          required,
        },
        workingArea: {
          required,
        },
        professionalQualification: {
          required,
        },
        qualification: {
          required,
        },
        jobPosition: {
          required,
        },
        employeeType: {
          required,
        },
      },
    },

    methods: {

      onOpenSelect() {
        setTimeout(() => {
          let style = jQuery('.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content').attr("style");
          jQuery('.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content').attr("style", style + 'max-width: 550px;')
        }, 50);
      },

      getValidationClass(fieldName) {
        let field = this.$v.employee[fieldName];
        if (field) {
          return {
            'md-invalid': (field.$invalid && field.$dirty)
          }
        }
      },

      validateEmployee() {

        this.$v.$touch();
        this.invalid = this.$v.$invalid;

      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss" scoped>
  .md-checkbox {
    margin: 5px;
  }
</style>

<style lang="scss">
  .md-menu-content {
    max-width: 550px;
  }
</style>