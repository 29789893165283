import Address from "../entities/Address"
import Contact from "../entities/Contact"

export default {

  name: 'Employee',

  employee: {
    id: '',
    absenceTimes: [],
    active: true,
    alerts: [{
      state: '',
      type: null,
      text: '',
    }],
    workingTimes: [],
    healthInsurance: null,
    imageId: null,
    instructionOnSafetyAndHealthProtectionDate: null,
    socialSecurityNumber: null,
    monthlySalary: null,
    facilityReference: '',
    facilityName: '',
    contractStart: null,
    contractEnd: null,
    limitedUntil: null,
    divisionType: 'UNDEFINED',
    secondDivisionType: 'UNDEFINED',
    secondWorkingArea: null,
    elternzeit: false,
    employeeType: null,
    jobPosition: null,
    qualification: null,
    professionalQualification: null,
    firstMeaslesVaccinationDate: null,
    secondMeaslesVaccinationDate: null,
    certificateOfGoodConductDate: null,
    firstAidCourseDate: null,
    workingArea: null,
    workingAreaOne: true,
    totalHoursPerWeek: 0,

    masterdata: {
      address: Address.address,
      birthdate: null,
      childRelation: 'SELF',
      contact: Contact.contact,
      firstname: '',
      lastname: '',
      nationality: 'DE',
      pickUpEntitled: true,
      salutation: null,
      sex: 'MALE',
      title: '',
    },
  }
}
