<template>
  <div>
    <div v-if="employee && selectedWorkingTime">

      <div class="grid-x">
        <div class="cell text-center">
          <md-button class="md-icon-button md-primary" @click="onNextWorkingTime"
                     :disabled="selectedWorkingTimeIndex + 1 >= employee.workingTimes.length">
            <md-icon>chevron_left</md-icon>
            <md-tooltip style="font-size: medium;">Vorherige Arbeitszeit</md-tooltip>
          </md-button>
          <span class="work-times">Arbeitszeiten&nbsp;{{employee.workingTimes.length - selectedWorkingTimeIndex}}&nbsp;/&nbsp;{{employee.workingTimes.length}}</span>
          <md-button class="md-icon-button md-primary" @click="onPrevWorkingTime"
                     :disabled="selectedWorkingTimeIndex <= 0">
            <md-icon>chevron_right</md-icon>
            <md-tooltip style="font-size: medium;">Nächste Arbeitsszeit</md-tooltip>
          </md-button>
        </div>
        <div class="cell text-center">
          <span class="validity-period">Gültig von: <b>{{selectedWorkingTime.validFrom | moment("DD.MM.YYYY")}}</b> bis <b>{{selectedWorkingTime.validUntil | moment("DD.MM.YYYY")}}</b></span>
        </div>
        <div class="cell text-center">
          <span class="validity-description">Anzahl Wochenstunden: <b>{{selectedWorkingTime.hoursPerWeek}}</b></span>
        </div>
        <div class="cell text-center">
          <span class="validity-description">Arbeitsbereich: <b>{{labels.workingArea[selectedWorkingTime.workingArea]}}</b></span>
        </div>
        <div class="cell text-center">
          <span class="validity-description">Einrichtung: <b>{{getFacilityName(selectedWorkingTime.facilityRefNr)}}</b></span>
        </div>
        <div class="cell">
          <md-switch class="md-primary" v-model="showClosedDays">
            <div>Alle Tage für Einrichtung <b><span>{{getFacilityName(employee.facilityReference)}}</span></b> anzeigen</div>
          </md-switch>
        </div>
      </div>

      <div class="grid-x">
        <div class="cell large-9 medium-7 small-7 text-left">
          <h5 style="font-size: x-large">&nbsp;{{selectedWorkingMonthDate | moment('MMMM YYYY')}}</h5>
        </div>
        <div class="cell large-1 medium-2 small-2 text-right">
          <md-button class="md-icon-button md-primary" @click="onPrevWorkingMonth">
            <md-icon>chevron_left</md-icon>
            <md-tooltip style="font-size: medium;">Vorheriger Monat</md-tooltip>
          </md-button>
        </div>
        <div class="cell large-1 medium-2 small-2 text-center">
          <md-button class="md-icon-button md-primary" @click="onTodaysWorkingMonth">
            <md-icon>today</md-icon>
            <md-tooltip style="font-size: medium;">Heute</md-tooltip>
          </md-button>
        </div>
        <div class="cell large-1 medium-1 small-1 text-left">
          <md-button class="md-icon-button md-primary" @click="onNextWorkingMonth">
            <md-icon>chevron_right</md-icon>
            <md-tooltip style="font-size: medium;">Nächster Monat</md-tooltip>
          </md-button>
        </div>
      </div>

      <table id="calendar">
        <thead>
        <tr>
          <th v-for="(day, index) of $material.locale.days" :key="index" v-if="showDay(day)">
            {{day.substring(0,2)}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in [0, 1, 2, 3, 4, 5]" :key="row">
          <td v-for="(day, index) of $material.locale.days" :key="index"
              :style="isInWorkingTimeStyle(index, row)" v-if="showDay(day)">
            <div class="grid-x">
              <div class="cell large-9 text-left">
                <div v-if="isInValidityPeriod(index, row) && isOpenDay(day)">
                  <div v-if="isInMonth(index, row)">
                    <span style="color: black; font-size: 11px">{{labels.qualification[selectedWorkingTime.qualification]}}</span>
                    <br>
                    <span v-if="selectedWorkingTime.workingAreaOne" style="color: darkseagreen;">Arbeit</span>
                    <span v-else style="color: darkseagreen;">Arbeit</span>
                  </div>
                  <div v-else>
                    <br><span style="color: lightgray; font-style:italic;">Arbeit</span>
                  </div>
                  <md-tooltip md-delay="500" v-html="workingTimeDetails(getDate(index, row))"></md-tooltip>
                </div>
                <div v-else>
                  <br><span style="color: lightgray; font-style:italic">Freizeit</span>
                </div>
              </div>
              <div class="cell large-3 text-right">
                <br>
                <div v-if="isInMonth(index, row)" :class="isTodayClass(index, row)" style="font-size: larger">
                  {{getDay(index, row)}}
                </div>
                <div v-else class="cell text-right" style="font-size: larger; color: lightgray; font-style:italic;">
                  {{getDay(index, row)}}
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <br>
      <div class="grid-x grid-padding-x">
        <div class="cell large-8 medium-6">
          <button v-if="!employee.archived && (isAdmin || isFacilityAdmin || isHrManager) && canWrite" type="button" @click="onEditWorkingTime" class="button success expanded">
            <md-icon>edit</md-icon>
            <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Arbeitszeiten bearbeiten
          </button>
          <button v-else @click="onViewWorkingTime" class="button expanded secondary">
            <i class="fi-eye"></i>&nbsp;&nbsp;&nbsp;Arbeitszeiten einsehen
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!employee.archived && (isAdmin || isFacilityAdmin || isHrManager) && canWrite" class="grid-x grid-padding-x">
        <div class="cell large-6">
          <button type="button" @click="onNewWorkingTime" class="button success expanded">
            <md-icon>add</md-icon>
            <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Arbeitszeiten anlegen
          </button>
        </div>
        <div class="cell large-6">&nbsp;</div>
      </div>
    </div>
    <div v-if="employee">
      <EditWorkingTimesDialog ref="EditWorkingTimesDialog" :employee="employeeCopy" :mode="dialogMode"
                              :workingTimes="employee.workingTimes"
                              :showDialog="showEditWorkingTimesDialog"
                              :groups="groups"
                              @updateWorkingTimeSuccess="onUpdateWorkingTimeSuccess"
                              @closeEditWorkingTimesDialog="showEditWorkingTimesDialog = false"></EditWorkingTimesDialog>
    </div>
  </div>
</template>

<script>

  import BookingSlotView from './BookingSlotView'
  import EditWorkingTimesDialog from '../../components/dialogs/EditWorkingTimesDialog'
  import Vue from 'vue'

  export default {
    name: 'WorkingTimesView',

    components: {
      BookingSlotView,
      EditWorkingTimesDialog,
    },

    props: {
      employee: {
        required: true
      },
      groups: {
        required: true
      },
    },

    data: () => ({
      WorkingTimeLabels: ['Morgens', 'Vormittags', 'Mittags', 'Nachmittags', 'Abends'],
      selectedWorkingTime: null,
      selectedWorkingTimeIndex: 0,
      showEditWorkingTimesDialog: false,
      dialogMode: 'update',
      employeeCopy: null,
      // workingTimesBackup: null,
      success: false,
      selectedWorkingMonthDate: Vue.moment(Vue.moment().format('YYYY-MM') + '-01'),
      selectedWorkingMonthFirstDayOfWeek: 0,
      selectedWorkingMonthIndex: 0,

      showClosedDays: false,
    }),

    watch: {
      employee() {
        this.resetIndices();
        this.onTodaysWorkingMonth();
      },
    },

    created() {
      this.selectWorkingTime();
    },

    updated() {
      this.selectWorkingTime();
    },

    methods: {

      resetStates() {
        this.success = false;
      },

      resetIndices() {
        this.selectedWorkingTimeIndex = 0;
        this.selectedWorkingMonthFirstDayOfWeek = 0;
        this.selectedWorkingMonthIndex = 0;
        this.selectWorkingTime();
      },

      isTodayClass(index, row) {
        let a = this.$moment(this.selectedWorkingMonthDate).add(-this.selectedWorkingMonthFirstDayOfWeek + index + row * 7, 'd').format('D');
        let b = this.$moment().subtract(1, 'd').format('D');
        if (a === b && this.selectedWorkingMonthIndex === 0) {
          return 'cell text-right is-today';
        }
        return 'cell text-right';
      },

      isInMonth(index, row) {
        return this.$moment(this.selectedWorkingMonthDate).add(-this.selectedWorkingMonthFirstDayOfWeek + index + row * 7, 'd').format('MMMM') === this.$moment(this.selectedWorkingMonthDate).format('MMMM');
      },

      getDay(index, row) {
        return this.$moment(this.selectedWorkingMonthDate).add(- this.selectedWorkingMonthFirstDayOfWeek + index + row * 7, 'd').format('D');
      },

      getDate(index, row) {
        return this.$moment(this.selectedWorkingMonthDate).add(- this.selectedWorkingMonthFirstDayOfWeek + index + row * 7, 'd').format('D. MMMM YYYY');
      },

      isInWorkingTimeStyle(index, row) {
        if (!(this.$moment(this.selectedWorkingMonthDate).add(-this.selectedWorkingMonthFirstDayOfWeek + index + row * 7, 'd').isAfter(this.selectedWorkingTime.validUntil) ||
          this.$moment(this.selectedWorkingMonthDate).add(-this.selectedWorkingMonthFirstDayOfWeek + index + row * 7, 'd').isBefore(this.selectedWorkingTime.validFrom))) {
          if (this.isInMonth(index, row)) {
            if (this.selectedWorkingTime.workingAreaOne) {
              return 'background: #8fbc8f24;';
            } else {
              return 'background: #38af3847;';
            }
          }
        } else {
          return '';
        }
      },

      isInValidityPeriod(index, row) {
        let selectedDate = this.$moment(this.selectedWorkingMonthDate).add(-this.selectedWorkingMonthFirstDayOfWeek + index + row * 7, 'd');
        return !(this.$moment(selectedDate).isBefore(this.selectedWorkingTime.validFrom) || this.$moment(selectedDate).isAfter(this.selectedWorkingTime.validUntil));
      },

      selectWorkingTime() {
        if (this.employee && this.employee.workingTimes && this.employee.workingTimes.length > this.selectedWorkingTimeIndex) {
          this.selectedWorkingTime = this.employee.workingTimes[this.selectedWorkingTimeIndex];
          this.adaptWorkingMonth();
        } else {
          this.selectedWorkingTime = null;
        }
      },

      getGroupName(groupId) {
        let name = '';
        this.groups.forEach(group => {
          if (group.id === groupId) {
            name = group.name;
          }
        })
        return name;
      },

      getFacilityName(referenceNumber) {
        if (referenceNumber && referenceNumber.length > 0 && this.organization.facilities) {
          for (let facility of this.organization.facilities) {
            if (facility.referenceNumber === referenceNumber) {
              return facility.name;
            }
          }
        }
        return '';
      },

      isOpenDay(day) {
        let time = false;
        if (this.organization.facilities) {
          this.organization.facilities.forEach(facility => {
            if (facility.referenceNumber === this.employee.facilityReference) {
              if (facility.kibigData) {
                time = facility.kibigData.openingDays.includes(day.toUpperCase().substr(0, 2));
              }
            }
          })
        }
        return !!time
      },

      showDay(day) {
        return this.isOpenDay(day) || this.showClosedDays;
      },

      workingTimeDetails(date) {
        let details = '<b>' + date + '</b><br><br>';
        details += 'Bereich: <b>' + this.labels.divisiontype[this.selectedWorkingTime.divisionType] + '</b><br>';
        details += 'Qualifikation: <b>' + this.labels.qualification[this.selectedWorkingTime.qualification] + '</b><br>';
        details += 'Mitarbeiter-Typ: <b>' + this.labels.employeeType[this.selectedWorkingTime.employeeType] + '</b><br>';
        if (this.selectedWorkingTime.workingAreaOne) {
          details += "Arbeitsbereich: ";
        }
        else {
          details += "<span style='color: greenyellow;'><b>2.</b> Arbeitsbereich: </span>";
        }
        details += '<b>' + this.labels.workingArea[this.selectedWorkingTime.workingArea] + '</b><br>';
        details += 'Einrichtung: <b>' + this.getFacilityName(this.selectedWorkingTime.facilityRefNr) + '</b><br>';
        details += 'Gruppe: <b>' + this.getGroupName(this.selectedWorkingTime.group) + '</b><br>';
        details += 'Anzahl Wochenstunden: <b>' + this.selectedWorkingTime.hoursPerWeek + '</b><br>';
        return details;
      },

      onEditWorkingTime() {
        this.dialogMode = 'update';
        this.employeeCopy = JSON.parse(JSON.stringify(this.employee));
        // this.workingTimesBackup = JSON.parse(JSON.stringify(this.employee.workingTimes));
        this.$refs.EditWorkingTimesDialog.selectedWorkingTimeIndex = this.selectedWorkingTimeIndex;
        this.showEditWorkingTimesDialog = true;
      },

      onViewWorkingTime() {
        this.dialogMode = 'view';
        this.employeeCopy = JSON.parse(JSON.stringify(this.employee));
        this.$refs.EditWorkingTimesDialog.selectedWorkingTimeIndex = this.selectedWorkingTimeIndex;
        this.showEditWorkingTimesDialog = true;
      },

      onNewWorkingTime() {
        this.dialogMode = 'add';
        let newWorkingTime = {
          refNr: -1,
          validFrom: null,
          validUntil: null,
          hoursPerWeek: 0,
          qualification: '',
          divisionType: '',
          employeeType: '',
          group: '',
          workingArea: '',
          workingAreaOne: true,
        };
        // this.workingTimesBackup = JSON.parse(JSON.stringify(this.employee.workingTimes));
        this.employeeCopy = JSON.parse(JSON.stringify(this.employee));
        this.employeeCopy.workingTimes.splice(0, 0, newWorkingTime);
        this.showEditWorkingTimesDialog = true;
      },

      onUpdateWorkingTimeSuccess(updatedEmployee) {
        if (this.dialogMode === 'add') {
          this.selectedWorkingTime = updatedEmployee.currentWorkingTime;
        }
        if (this.canWrite) {
          this.$emit("updateEmployees", updatedEmployee);
          this.$store.commit('successMsg', 'Die Änderungen der Arbeitszeit für Mitarbeiter <b>'
              + updatedEmployee.masterdata.fullName + '</b> wurden erfolgreich durchgeführt!');
          this.success = true;
        }
      },

      onPrevWorkingTime() {
        if (this.selectedWorkingTimeIndex > 0) {
          this.selectedWorkingTimeIndex--;
          this.selectWorkingTime();
          this.resetWorkingMonthIndex(this.selectedWorkingTime.validFrom);
        }
      },

      onNextWorkingTime() {
        if (this.selectedWorkingTimeIndex < this.employee.workingTimes.length - 1) {
          this.selectedWorkingTimeIndex++;
          this.selectWorkingTime();
          this.resetWorkingMonthIndex(this.selectedWorkingTime.validFrom);
        }
      },

      resetWorkingMonthIndex(date) {
        if (this.selectedWorkingTime) {
          this.selectedWorkingMonthIndex = Vue.moment(date, 'YYYY-MM-DD').diff(Vue.moment(Vue.moment().format('YYYY-MM') + '-01', 'YYYY-MM-DD'), 'months');
          this.selectedWorkingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedWorkingMonthIndex, 'M');
        }
      },

      adaptWorkingMonth() {
        let firstDayOfWeek = Vue.moment(Vue.moment(this.selectedWorkingMonthDate).format('YYYY-MM') + '-01').day() - 1;
        if (firstDayOfWeek < 1) {
          this.selectedWorkingMonthFirstDayOfWeek = firstDayOfWeek + 7;
        } else {
          this.selectedWorkingMonthFirstDayOfWeek = firstDayOfWeek;
        }
      },

      onPrevWorkingMonth() {
        if (Vue.moment(this.selectedWorkingMonthDate).isAfter(this.employee.workingTimes[0].validFrom)) {
          this.selectedWorkingMonthIndex--;
          this.selectedWorkingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedWorkingMonthIndex, 'M');
          if (this.selectedWorkingTime) {
            if (Vue.moment(this.selectedWorkingMonthDate).isBefore(Vue.moment(this.selectedWorkingTime.validFrom, 'YYYY-MM-DD'))) {
              this.onPrevWorkingTime();
            }
          }
          this.adaptWorkingMonth();
        }
      },

      onTodaysWorkingMonth() {
        this.selectedWorkingMonthIndex = 0;
        this.selectedWorkingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedWorkingMonthIndex, 'M');
        for (let i = 0; i < this.employee.workingTimes.length; i++) {
          if ((Vue.moment(this.employee.workingTimes[i].validFrom).isSameOrBefore(this.selectedWorkingMonthDate) &&
              (Vue.moment(this.employee.workingTimes[i].validUntil).isSameOrAfter(this.selectedWorkingMonthDate)))) {
            this.selectedWorkingTimeIndex = i;
            this.selectedWorkingTime = this.employee.workingTimes[this.selectedWorkingTimeIndex];
            break;
          }
        }
        this.adaptWorkingMonth();
      },

      onNextWorkingMonth() {
        if (Vue.moment(this.selectedWorkingMonthDate).isBefore(Vue.moment(this.employee.workingTimes[this.employee.workingTimes.length - 1].validUntil, 'YYYY-MM-DD').subtract(1, 'M'))) {
          this.selectedWorkingMonthIndex++;
          this.selectedWorkingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedWorkingMonthIndex, 'M');
          if (this.selectedWorkingTime) {
            if (Vue.moment(this.selectedWorkingMonthDate).isAfter(Vue.moment(this.selectedWorkingTime.validUntil, 'YYYY-MM-DD'))) {
              this.onNextWorkingTime();
            }
          }
          this.adaptWorkingMonth();
        }
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      organization() {
        return this.$store.getters.organization
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isHrManager() {
        return this.$store.getters.hrmanager;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },
    },
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 10px;
  }

  span {
    color: cornflowerblue;
  }

  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: grey;
    empty-cells: hide;
    width: 100%;
    margin: 4px 0 0;
  }

  th {
    width: 12.5%;
    font-weight: lighter;
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  #calendar {
    border-collapse: collapse;
    width: 100%;
  }

  #calendar table {
    font-weight: lighter;
    border-spacing: 2px;
  }

  #calendar td, #calendar th {
    width: 12.5%;
    border: 2px solid #fff;
    padding: 5px;
  }

  .md-theme-dark #calendar td,
  .md-theme-dark #calendar th {
    width: 12.5%;
    border: 2px solid #424242;
    padding: 5px;
  }

  #calendar tr {
    background-color: #fafafa;
  }

  .md-theme-dark #calendar tr {
    background-color: #525252;
  }

  #calendar tr:hover {
    background-color: rgba(255, 193, 7, 0.1);
  }

  .md-theme-dark #calendar tr:hover {
    background-color: rgba(37, 28, 2, 0.1);
  }

  #calendar th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: right;
    background: rgba(255, 193, 7, 0.4);
  }

  .md-theme-dark #calendar th {
    background: #666666;
  }

  .is-today {
    background: darkgreen;
    color: white;
    text-align: center;
  }

  .work-times {
    font-weight: 400;
    font-size: x-large;
    color: lightseagreen;
    position: relative;
    top: 10px;
  }

  .validity-period {
    text-align: left;
    padding: 5px;
    font-weight: 300;
    font-size: large;
  }

  .validity-description {
    text-align: left;
    padding: 5px;
    font-weight: 300;
    font-size: medium;
    color: lightseagreen;
  }

  .button {
    margin-bottom: 0;
  }

  #division-type .KRIPPE {
    color: lightpink;
    background: none;
  }

  #division-type .KINDERGARTEN {
    color: lightskyblue;
    background: none;
  }

  #division-type .HORT {
    color: lightgreen;
    background: none;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 550px;
    white-space: pre-wrap;
  }
</style>