<template>
  <div v-if="selectedEmployee">

    <md-tabs id="employeeTabsId" md-dynamic-height md-elevation="1" @md-changed="onTabsChanged">
      <template slot="md-tab" slot-scope="{ tab }">
        <md-icon>{{tab.icon}}</md-icon>
        <span class="md-tab-label" style="margin-top: 10px;">{{ tab.label }} <i class="badge" v-if="tab.data.badge">1</i></span>
        <md-tooltip>
          <div v-if="tab.data.badge">Einwilligung zur Datenverarbeitung für <b>{{tab.data.employee.masterdata.fullName}}</b> fehlt!</div>
        </md-tooltip>
      </template>

      <md-tab md-label="Persönliche Daten" md-icon="person"
              :md-template-data="{ badge: !selectedEmployee.masterdata.dsgvoConfirmationObtained, employee: selectedEmployee }">
        <md-card-content v-if="selectedEmployee" style="height: 555px; overflow-x: auto; padding: 0; margin-top: 0;">
          <div v-if="editActive">
            <h5>Mitarbeiter</h5>
            <PersonForm ref="employeePersonForm" :person="selectedEmployee.masterdata"
                        @updateBirthDate="selectedEmployee.masterdata.birthdate = arguments[0]" :sending="sending"></PersonForm>
            <br>
            <h5>Adresse</h5>
            <AddressForm ref="employeeAddressForm" :address="selectedEmployee.masterdata.address"
                         :sending="sending"></AddressForm>
            <br>
            <h5>Kontakt</h5>
            <ContactForm ref="employeeContactForm" :contact="selectedEmployee.masterdata.contact"
                         :sending="sending"></ContactForm>
            <br>
            <h5>Merkmale</h5>
            <EmployeeForm ref="employeeForm" :employee="selectedEmployee" :sending="sending" characteristics="true"></EmployeeForm>
            <br>
            <h5>KiBiG-Web</h5>
            <EmployeeForm ref="employeeForm" :employee="selectedEmployee" :availableFacilities="availableFacilities"
                          :sending="sending" kibig="true"></EmployeeForm>
          </div>
          <div v-else>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Mitarbeiter</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <PersonView :facilityRef="selectedEmployee.facilityReference" :person="selectedEmployee.masterdata" :noAddress="true"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Adresse</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <AddressView :address="selectedEmployee.masterdata.address"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Kontakt</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <ContactView :contact="selectedEmployee.masterdata.contact"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Merkmale</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <EmployeeView :employee="selectedEmployee" characteristics="true"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>KiBiG-Web</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <EmployeeView :employee="selectedEmployee" kibig="true"/>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-tab>
      <md-tab md-label="Arbeits Daten" md-icon="domain">
        <md-card-content v-if="selectedEmployee" style="height: 555px; overflow-x: auto; padding: 0; margin-top: 0;">
          <div v-if="editActive">
            <h5>Arbeitnehmer</h5>
            <EmployeeForm ref="employeeForm" :employee="selectedEmployee" :availableFacilities="availableFacilities"
                          :sending="sending"></EmployeeForm>
            <br>
            <h5>Vertrag</h5>
            <EmployeeForm ref="employeeForm" :employee="selectedEmployee" :availableFacilities="availableFacilities"
                          :sending="sending" enableContractDetails="true" @updateEmployeeContractDocument="updateEmployeeContractDocument"></EmployeeForm>
          </div>
          <div v-else>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Arbeitnehmer</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <EmployeeView :employee="selectedEmployee"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Vertrag</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <EmployeeView :employee="selectedEmployee" enableContractDetails="true"/>
              </div>
            </div>
            <hr>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Vertragsdokument</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <md-button class="md-icon-button md-raised md-third"
                           @click="onDownloadContractDocument()"
                           :disabled="!selectedEmployee.documentAvailable || sending">
                  <md-icon>archive</md-icon>
                  <md-tooltip>Download Vertragsdokument <b>{{selectedEmployee.documentFileName}}</b></md-tooltip>
                </md-button>
                <md-chip v-if="selectedEmployee.documentAvailable" class="md-primary margin-top">
                  <span>{{ selectedEmployee.documentFileName }}</span>
                </md-chip>
                <md-chip v-else md-disabled class="margin-top">
                  <i>Kein Dokument hinterlegt.</i>
                </md-chip>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-tab>
      <md-tab md-label="Melde Daten" md-icon="done_all">
        <div v-if="selectedEmployee">
          <div v-if="editActive">
            <h5>Meldedaten</h5>
            <EmployeeReportingFrom ref="employeeReportingForm" :employee="selectedEmployee"
                                   :sending="sending"></EmployeeReportingFrom>
          </div>
          <div v-else>
            <div class="grid-x">
              <div class="cell large-3 medium-5 small-4">
                <h5>Meldedaten</h5>
              </div>
              <div class="cell large-9 medium-7 small-8">
                <EmployeeReportingView :employee="selectedEmployee"/>
              </div>
            </div>
          </div>
        </div>
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Person from "../../entities/Person"

  import AddressView from "../../components/views/AddressView";
  import ContactView from "../../components/views/ContactView";
  import PersonView from "../../components/views/PersonView";
  import EmployeeView from "../../components/views/EmployeeView";
  import EmployeeReportingView from "../../components/views/EmployeeReportingView";

  import PersonForm from "../forms/PersonForm";
  import EmployeeForm from "../forms/EmployeeForm";
  import EmployeeReportingFrom from "../../components/forms/EmployeeReportingFrom";
  import AddressForm from "../../components/forms/AddressForm";
  import ContactForm from "../../components/forms/ContactForm";
  import BinaryService from "@/services/BinaryService";
  import HttpErrorHandler from "@/services/HttpErrorHandler";

  export default {
    name: 'EmployeeTabs',

    components: {
      AddressView,
      ContactView,
      PersonView,
      EmployeeView,
      EmployeeForm,
      PersonForm,
      AddressForm,
      ContactForm,
      EmployeeReportingView,
      EmployeeReportingFrom,

      Person,
    },

    mounted() {
    },

    props: {
      selectedEmployee: {
        required: true
      },
      availableFacilities: {
        required: true
      },
      editActive: {
        required: false
      },
      sending: {
        required: false
      },
    },

    beforeDestroy() {
      this.cleanDownloads();
    },

    watch: {
      editActive() {
        setTimeout(() => jQuery('#employeeTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%'), 50);
      },
    },

    data: () => ({
      invalid: false,

      downloadUrl: null,
      downloadLink: null,
      downloadBlob: null,
    }),

    methods: {

      onTabsChanged() {
        setTimeout(() => jQuery('#employeeTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%'), 50);
      },

      reset() {
        if (this.$refs.employeePersonForm && this.$refs.employeeForm && this.$refs.employeeAddressForm && this.$refs.employeeContactForm) {
          this.$refs.employeePersonForm.$v.$reset();
          this.$refs.employeeForm.$v.$reset();
          this.$refs.employeeAddressForm.$v.$reset();
          this.$refs.employeeContactForm.$v.$reset();
        }
      },

      validateEmployee() {
        this.invalid = false;

        if (this.$refs.employeePersonForm && this.$refs.employeeForm && this.$refs.employeeAddressForm && this.$refs.employeeContactForm) {
          this.$refs.employeePersonForm.validatePerson();
          this.$refs.employeeForm.validateEmployee();
          this.$refs.employeeAddressForm.validateAddress();
          this.$refs.employeeContactForm.validateContact();
          if (this.$refs.employeePersonForm.invalid || this.$refs.employeeForm.invalid ||
              this.$refs.employeeAddressForm.invalid || this.$refs.employeeContactForm.invalid) {
            jQuery('.md-tabs-navigation').children(':button.md-button').first().trigger('click');
            this.invalid = true;
          }
        }
      },


      onDownloadContractDocument() {
        if (this.selectedEmployee) {
          this.downloadContractDocument();
        }
      },

      downloadContractDocument() {
        if (this.selectedEmployee) {
          BinaryService.downloadEmployeeContractDocument(this.selectedEmployee.documentId)
              .then((response) => {

                setTimeout(() => {
                  this.cleanDownloads();
                  this.downloadBlob = new Blob([response.data], {type: 'application/octet-stream'});
                  this.downloadUrl = window.URL.createObjectURL(this.downloadBlob);
                  this.downloadLink = document.createElement('a');
                  const id = this.selectedEmployee.documentFileName;
                  this.downloadLink.href = this.downloadUrl;
                  this.downloadLink.setAttribute('download', id);
                  this.downloadLink.setAttribute('id', id);
                  document.body.appendChild(this.downloadLink);
                  this.downloadLink.click();
                }, 250);
                this.$store.commit('successMsg', 'Das Vertragsdokument für Mitarbeiter <b>' + this.selectedEmployee.masterdata.fullName + '</b> wurde erfolgreich heruntergeladen.');
              })
              .catch(e => {
                HttpErrorHandler.handleError(e, this, 'Fehler beim Herunterladen des Vertragsdokument für Mitarbeiter <b>' + this.selectedEmployee.masterdata.fullName + '</b>');
              })
        }
      },

      cleanDownloads() {
        if (this.downloadUrl) {
          this.downloadBlob = null;
          document.body.removeChild(this.downloadLink);
          this.downloadLink = null;
        }
      },

      updateEmployeeContractDocument(employee) {
        this.selectedEmployee.documentAvailable = employee.documentAvailable;
        this.selectedEmployee.documentFileName = employee.documentFileName;
        this.selectedEmployee.documentId = employee.documentId;
        this.$emit('updateEmployeeContractDocument', employee);
      }
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      isSage() {
        return this.$store.getters.sage;
      },
    },
  }
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-tab {
    padding-left: 8px;
    padding-right: 8px;
  }

  .label {
    font-size: x-large;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .badge {
    width: 19px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
  }

  .margin-top {
    margin-top: 4px;
  }
</style>
