<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" id="createNewEmployeeDialog" v-if="employee" :md-active.sync="showDialog"
               @md-closed="closeDialog">
      <md-dialog-title>

        <form novalidate @submit.prevent="validateEmployee">

          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'update'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Daten bearbeiten - Mitarbeiter {{employee.masterdata.firstname}} {{employee.masterdata.lastname}}
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-5">
              <img width="20px" src="../../assets/icons/employee-torso.png"/>&nbsp;&nbsp;&nbsp;Neuen Mitarbeiter hinzufügen
            </div>
            <div class="cell large-2 medium-2" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Mitarbeiter speichern
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button" :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>

        </form>

      </md-dialog-title>

      <md-dialog-content id="dialogContentId">

        <div class="grid-x grid-padding-x">
          <div v-if="!isSage && availableFacilities"
               class="cell large-6 medium-6 small-12">
            <md-field :class="getValidationClass('facilityReference')">
              <label>Einrichtung</label>
              <md-select v-model="employee.facilityReference" :disabled="sending || availableFacilities.length <= 0">
                <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber" :key="facility.referenceNumber">
                  {{ facility.longName }}
                </md-option>
              </md-select>
              <span class="md-error" v-if="!$v.employee.facilityReference.required">Einrichtung ist notwendig</span>
            </md-field>
          </div>
          <div class="cell large-6 medium-6 hide-for-small-only">&nbsp;</div>
        </div>

        <br>

        <EmployeeTabs ref="employeeTabsForm" :availableFacilities="availableFacilities"
                      :selectedEmployee="employee" :editActive="editActive" :sending="sending"></EmployeeTabs>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingEmployeeDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../../assets/icons/employee-torso.png"/>&nbsp;&nbsp;&nbsp;Unvollständigen
          Datensatz für Mitarbeiter bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der unvollständige Datensatz wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="addEmployee" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeConfirmationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeConfirmationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>

</template>

<script>

  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate';
  import SessionTime from '../animations/SessionTime';

  import {
    required,
  } from 'vuelidate/lib/validators'

  import EmployeeTabs from "../../components/tabs/EmployeeTabs";
  import FacilityService from '../../services/FacilityService'
  import HttpErrorHandler from '../../services/HttpErrorHandler'

  export default {
    name: 'EditEmployeeDialog',
    mixins: [validationMixin],

    components: {
      EmployeeTabs,
      SessionTime,
    },

    props: {
      employee: {
        required: true
      },
      availableFacilities: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    validations: {
      employee: {
        facilityReference: {
          required,
        },
      }
    },

    created() {
    },

    data: () => ({
      success: true,
      sending: false,
      editActive: true,
      confirmMissingEmployeeDataDialog: false,
    }),

    methods: {

      addEmployee() {
        this.closeConfirmationDialog();
        this.sending = true;
        this.success = false;
        FacilityService.addEmployeeToFacility(this.employee.facilityReference, this.employee)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$emit("closeEditEmployeeDialog");
            this.$emit("updateEmployeeSuccess", response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen des Mitarbeiter ' + this.employee.masterdata.firstname + ' '
              + this.employee.masterdata.lastname);
            this.sending = false;
          })
      },

      confirmMissingData() {
        jQuery('#createNewEmployeeDialog').attr("style", "display:none");
        this.confirmMissingEmployeeDataDialog = true;
      },

      closeConfirmationDialog() {
        this.confirmMissingEmployeeDataDialog = false;
        jQuery('#createNewEmployeeDialog').attr("style", "display:normal");
        this.$v.$reset();
      },

      getValidationClass(fieldName) {
        let field = this.$v.employee[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateEmployee() {
        this.$v.$touch();
        this.$refs.employeeTabsForm.$refs.employeePersonForm.validatePerson();

        if (this.employee.facilityReference === '') {
          jQuery('.md-tabs-navigation').children(':button.md-button').first().trigger('click');
          jQuery('#dialogContentId').animate({scrollTop: 0}, 'slow');
          return;
        }

        if (this.$refs.employeeTabsForm.$refs.employeePersonForm.invalid) {
          jQuery('.md-tabs-navigation').children(':button.md-button').first().trigger('click');
          jQuery('#dialogContentId').animate({scrollTop: 110}, 'slow');
          return;
        }

        this.$refs.employeeTabsForm.validateEmployee();

        if (!this.$refs.employeeTabsForm.invalid) {
          if (this.mode === 'add') {
            this.addEmployee();
          }
        } else {
          setTimeout(() => this.confirmMissingData(), 250);
        }
      },

      closeDialog() {
        this.$emit("closeEditEmployeeDialog");
        this.$v.$reset();
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization;
      },
    },
  };
</script>

<style scoped>
  .md-dialog /deep/.md-dialog-container {
    min-width: 95%;
  }
</style>