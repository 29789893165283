<template>
  <div v-if="isAuthenticated && organization" :class="fullViewClass">
    <div v-if="organization.employeeModuleActive">
      <div class="cell callout success">
        <div class="grid-x">
          <div class="cell small-8">
            <h3 v-if="!archiveActive"><i class="fi-torsos-all" style="color: black;"></i>&nbsp;&nbsp;&nbsp;Mitarbeiter</h3>
            <h3 v-else class="flash-orange-red"><i class="fi-torsos-all" style="color: black;"></i>&nbsp;&nbsp;&nbsp;Mitarbeiter-Archiv</h3>
          </div>
          <div class="cell small-4 text-right">
            <md-switch id="archiveSwitchId" v-model="archiveActiveToggle" @change="onToggleArchive" :disabled="editActive"
                       style="margin: 11px 11px 11px 0;"><span
                style="font-weight: lighter; font-size: large;">Archiv</span></md-switch>
          </div>
        </div>
      </div>

      <div id="overlayLeft" class="hide-for-small-only hide-for-medium-only" style="width: 50%; top: 18%; height: 76%;" @click="onTouchOverlay"></div>

      <div v-if="organization && organization.facilities && organization.facilities.length <= 0" class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Mitarbeiter"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">

        <div class="cell large-6">
          <md-table ref="employeesTable" v-model="searched" md-sort="urgency"
                    md-sort-order="desc" md-card md-fixed-header style="height: 1010px; overflow-x: hidden;"
                    @md-selected="onSelect" :md-selected-value.sync="selectedRows">
            <md-table-toolbar id="employeeTableToolBarId" >
              <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                <div class="cell large-3 hide-for-medium-only hide-for-small-only">
                  <p v-if="!archiveActive" class="md-title ai-number">{{ searched.length }} Mitarbeiter</p>
                  <p v-else class="md-title ai-number" style="color: orangered">{{ searched.length }} Mitarbeiter</p>
                  <p v-if="(!archiveActive && employees.length - searched.length > 0) || (archiveActive && archivedEmployees.length - searched.length > 0)"
                     style="color: crimson; margin: 0;">
                    <span v-if="!archiveActive">{{ employees.length - searched.length }}</span>
                    <span v-else>{{ archivedEmployees.length - searched.length }}</span> nicht sichtbar
                    <md-tooltip md-direction="bottom" md-delay="500">
                      <b>Warum nicht sichtbar?</b><br><br>
                      <span>Durch setzen von Filtern wird die Liste eingeschränkt.</span><br><br>
                      <span>Klicken Sie auf <md-button class="md-icon-button md-raised md-primary"
                                                       style="background: cornflowerblue; margin-bottom: 10px">
                                <md-icon style="color: white">select_all</md-icon>
                              </md-button> um alle Einträge zu sehen.</span>
                    </md-tooltip>
                  </p>
                  <p v-else style="color: seagreen; margin: 0;">
                    Alle sichtbar
                  </p>
                </div>
                <div class="cell large-3 medium-0 small-0">
                  <div v-if="sending">
                    <vue-simple-spinner></vue-simple-spinner>
                  </div>
                  <div v-else>
                    <div class="md-layout">
                      <div class="md-layout-item">
                        <md-button class="md-icon-button md-raised md-primary" style="background: cornflowerblue" @click="onShowAllEmployees">
                          <md-icon>select_all</md-icon>
                          <md-tooltip md-direction="bottom" style="max-width: 500px">
                            <span v-if="!archiveActive">Alle <b>{{employees.length}}</b> Mitarbeiter der Einrichtung <b>{{selectedFacilityName}}</b> anzeigen</span>
                            <span v-else>Alle <b>{{archivedEmployees.length}}</b> archivierten Mitarbeiter der Einrichtung <b>{{selectedFacilityName}}</b> anzeigen</span>
                          </md-tooltip>
                        </md-button>
                      </div>
                      <div class="md-layout-item">
                        <md-button class="md-icon-button md-raised md-primary"
                                   @click="$router.push('/Drucken/Listen')">
                          <md-icon>print</md-icon>
                          <md-tooltip md-direction="bottom">
                            Aktuelle Liste drucken
                          </md-tooltip>
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!isSage && availableFacilities && availableFacilities.length > 0"
                     class="cell large-6 medium-12 small-12">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber" :disabled="sending"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber" :key="facility.referenceNumber">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <md-field>
                    <label>Bereiche</label>
                    <md-select v-model="selectedDivisions" :disabled="sending" @md-selected="onSelectDivision" multiple>
                      <md-option v-for="division of labels.divisionTypes" :key="division"
                                 :value="division">
                        {{labels.divisiontype[division]}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <md-field>
                    <label>Qualifikation</label>
                    <md-select v-model="selectedQualifications"
                               :disabled="sending" @md-selected="onSelectQualification" multiple>
                      <md-option v-for="qualification of labels.qualifications" :value="qualification" :key="qualification">
                        {{labels.qualification[qualification]}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div v-if="!archiveActive" class="cell large-6 medium-12 small-12">
                  <md-field>
                    <label>Filter</label>
                    <md-select v-model="selectedFilter" :disabled="sending" @md-selected="onSelectFilter" multiple>
                      <md-option v-for="filter of filters" :value="filter.id" :key="filter.id">
                        {{filter.name}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div v-if="archiveActive" class="cell large-6 medium-6 small-6">
                  <md-field>
                    <label>Jahr Archivierung</label>
                    <md-select v-model="selectedYears" :disabled="sending" @md-selected="searchOnTable();" multiple>
                      <md-option v-for="year of archiveYears" :value="year" :key="year">
                        {{ year }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div v-if="!isSage && availableFacilities && availableFacilities.length > 0"
                     class="cell large-6 medium-12 small-12">
                  <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Suche Nachname des Mitarbeiters ..." v-model="search"
                              @input="searchOnTable"/>
                  </md-field>
                </div>
                <div v-else class="cell large-6 medium-12 small-12">
                  <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Suche Nachname des Mitarbeiters ..." v-model="search"
                              @input="searchOnTable"/>
                  </md-field>
                </div>

                <div v-if="canWrite" class="cell" style="padding-bottom: 15px;">
                  <div v-if="isAdmin || isFacilityAdmin || isHrManager" class="grid-x grid-padding-x">
                    <div class="cell medium-6 large-6 hide-for-small-only">
                      <div v-if="togglingAddMode"  class="grid-x grid-padding-x">
                        <div class="cell small-8">
                          <h3 style="font-size: x-large;margin-bottom: 0px;"><p style="text-align:center; margin: 0;">Moment bitte ... </p></h3>
                        </div>
                        <div class="cell small-4 text-left">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                      </div>
                    </div>
                    <div v-if="!archiveActive" class="cell medium-6 large-6">
                      <md-button @click="onAddEmployee"
                                 :disabled="togglingAddMode || (editActive && selected !== null) || sending || !(isAdmin || isFacilityAdmin || isHrManager) || !canWrite"
                                 class="md-icon-button md-raised md-primary">
                        <md-icon>person_add</md-icon>
                        <md-tooltip>Neuen Mitarbeiter hinzufügen</md-tooltip>
                      </md-button>
                      <md-button @click="onDeleteEmployeeDialog"
                                 :disabled="!selected || editActive || sending || !(isAdmin || isFacilityAdmin || isHrManager) || !canWrite"
                                 class="md-icon-button md-raised md-accent">
                        <md-icon>delete</md-icon>
                        <md-tooltip>Datensatz Mitarbeiter löschen</md-tooltip>
                      </md-button>
                      <md-button @click="onArchiveEmployeeDialog"
                                 :disabled="!selected || editActive || sending || !(isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                                 class="md-icon-button md-raised md-fifth">
                        <md-icon>archive</md-icon>
                        <md-tooltip>Mitarbeiter archivieren</md-tooltip>
                      </md-button>
                    </div>
                    <div v-else class="cell medium-6 large-6"></div>
                  </div>
                </div>

              </div>
            </md-table-toolbar>

            <md-table-empty-state v-if="search"
                                  md-label="Kein Mitarbeiter gefunden"
                                  :md-description="`Kein Mitarbeiter mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
            </md-table-empty-state>

            <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                          :style="absenceStyle(item.elternzeit)" md-selectable="single">
              <md-table-cell md-label="Vorname" md-sort-by="masterdata.firstname">
                <ImageView :imageId="item.imageId" size="micro" adult="true"></ImageView>&nbsp;&nbsp;&nbsp;
                {{item.masterdata.firstname}}
              </md-table-cell>
              <md-table-cell md-label="Nachname" md-sort-by="masterdata.lastname">
                {{item.masterdata.lastname}}
              </md-table-cell>
              <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Alter" md-sort-by="masterdata.birthdate">{{ item.ageTextual }}
              </md-table-cell>
              <md-table-cell md-label="Qualifikation" md-sort-by="qualification">
                {{labels.qualification[item.qualification]}}
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </md-table-cell>
              <md-table-cell md-label="Wochen h">
                <span v-if="item.workingTimes.length > 0">{{item.totalHoursPerWeek}}
                  <md-tooltip v-html="item.workingTimesTooltip" style="max-width: 1000px"></md-tooltip>
                </span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </md-table-cell>
              <md-table-cell md-label="KiBiG" md-sort-by="kibigName" style="padding: 0;">{{ item.kibigName }}<br>
                <span style="font-size: smaller">{{ item.kibigId }}</span>
              </md-table-cell>
              <md-table-cell md-label="Alarme" md-sort-by="urgency">
                <AlertIcons :alerts="item.alerts" index="-1" alert4employee="true" @updateAlertUrgency="item.urgency = arguments[0]"></AlertIcons>
              </md-table-cell>
              <md-tooltip md-delay="1000" style="max-width: 500px">
                <span v-if="item.elternzeit" class="ai-warn-text"><b>{{ item.masterdata.fullName }}</b> ist in Elternzeit</span>
              </md-tooltip>
            </md-table-row>
          </md-table>
        </div>

        <div class="cell large-6">
          <form novalidate @submit.prevent="validateEmployee">
            <md-card>
              <md-card-header style="height: 190px;">
                <div class="grid-x grid-padding-x">
                  <div class="cell large-3 medium-5 small-6">
                    <div v-if="selectedEmployee && !isSage">
                      <ImageView v-if="selectedEmployee.imageId" :imageId="selectedEmployee.imageId" size="small"></ImageView>
                      <div v-else-if="options.url" @click="onVdropzoneClick">
                        <div v-if="sending" style="margin-top: 4rem;margin-right: 1rem;">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                        <div v-else>
                          <vue-dropzone v-if="(isAdmin || isFacilityAdmin || isHrManager) && canWrite"
                                        ref="employeeImageDropzone" id="dropzone"
                                        :options="options" style="font-size: small;"
                                        @vdropzone-file-added="onVdropzoneFileAdded"
                                        @vdropzone-error="onVdropzoneError"
                                        @vdropzone-processing="onVdropzoneProcessing"
                                        @vdropzone-success="onVdropzoneSuccess">
                          </vue-dropzone>
                          <img v-else src="../assets/icons/user-account-woman.png" class="default-image">
                        </div>
                        <br>
                      </div>
                    </div>
                    <img v-else src="../assets/icons/user-account-woman.png" class="default-image">
                  </div>
                  <div v-if="selectedEmployee" class="cell large-9 medium-7 small-6">
                    <div class="md-title">{{selectedEmployee.masterdata.firstname}}
                      {{selectedEmployee.masterdata.lastname}} <span v-if="selectedEmployee.elternzeit"><b class="flash-orange-red"><i>in Elternzeit</i></b></span>
                    </div>
                    <div class="md-subhead" style="opacity: 1;">
                      <span style="color: lightseagreen; font-size: 1.2rem; font-weight: 300;">Geburtstag: <b>{{selectedEmployee.masterdata.birthdate | moment("DD.MM.YYYY")}}</b>&nbsp;</span>
                    </div>
                    <div style="margin-top: 10px;">
                      <span style="color: cornflowerblue; font-size: 1.2rem; font-weight: 300">
                        <b>{{labels.qualification[selectedEmployee.qualification]}}</b>
                      </span>
                      <br>
                      <span style="color: cornflowerblue; font-size: 1.0rem; font-weight: 300;">
                        {{labels.workingArea[selectedEmployee.workingArea]}}
                      </span>
                    </div>
                    <br>
                    <div class="grid-x">
                      <div class="cell large-10">
                        <span>SV-Nr: <b style="color: crimson; font-size: 1.0rem; font-weight: 300;">{{selectedEmployee.socialSecurityNumber}}</b></span><br>
                      </div>
                      <div class="cell medium-12 hide-for-large"><br><br></div>
                      <div v-if="!selectedEmployee.archived" class="cell large-2">
                        <div class="cell text-left">
                          <md-button class="md-icon-button md-raised md-accent"
                                     style="margin-top: -20px;"
                                     @click="deleteImageDialog"
                                     :disabled="selectedEmployee.imageId === null || sending || !(isAdmin || isFacilityAdmin || isHrManager) || !canWrite">
                            <md-icon>delete</md-icon>
                            <md-tooltip md-direction="left">Bild von <b>{{selectedEmployee.masterdata.firstname}}
                              {{selectedEmployee.masterdata.lastname}}</b> löschen
                            </md-tooltip>
                          </md-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </md-card-header>

              <md-card-content v-if="selectedEmployee" style="height: 820px;">
                <md-tabs id="employeeManagementTabsId" md-dynamic-height md-elevation="1" @md-changed="onTabsChanged" style="height: 820px;">
                  <template slot="md-tab" slot-scope="{ tab }">
                    <AlertIcons v-if="tab.data.index < 3" :alerts="selectedEmployee.alerts" :index="tab.data.index"></AlertIcons>
                    <img v-else class="center" src="../assets/icons/contract.svg"/>
                    <span class="md-tab-label" style="margin-top: 10px;">{{ tab.label }}</span>
                  </template>

                  <md-tab id="EMPLOYEE_DATA" md-label="Stamm-Daten" md-icon="list" :md-template-data="{index: 0}">
                    <div v-if="!isSage" class="grid-x grid-padding-x grid-padding-y" style="margin-bottom: 0;">
                      <div class="cell small-6">
                        <md-chip v-if="!selectedEmployee.archived && (isAdmin || isFacilityAdmin || isHrManager || isTreasurer) && canWrite"
                                 style="height: 50px">
                          <md-switch id="editActiveSwitchId" v-model="editActiveSwitch" value="true"
                                     @change="onToggleEditData(!editActive)"
                                     :disabled="togglingEditMode">
                            Daten bearbeiten
                          </md-switch>
                        </md-chip>
                        <div v-if="selectedEmployee.archived">
                          <h5 style="color: coral; text-transform: none; margin-bottom: 4px;"><i><b>archiviert</b>&nbsp;<span
                              style="font-size: small;">am {{selectedEmployee.archivingDate | moment("DD.MM.YYYY")}}</span></i></h5>
                        </div>
                      </div>
                      <div v-if="togglingEditMode" class="cell small-6">
                        <div class="grid-x grid-padding-x grid-padding-y" style="padding-top: 10px">
                          <div class="cell small-12 medium-2 text-left">
                            <vue-simple-spinner></vue-simple-spinner>
                          </div>
                          <div class="cell medium-10 hide-for-small-only"><h3 style="font-size: x-large">Moment bitte
                            ... </h3></div>
                        </div>
                      </div>
                      <div v-else class="cell small-6 text-right">
                        <md-button v-if="selectedEmployee.archived"
                                   :disabled="sending || !(isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                                   class="md-icon-button md-raised md-fifth"
                                   @click="onArchiveEmployeeDialog">
                          <md-icon>undo</md-icon>
                          <md-tooltip>Archivierung für <b>{{ selectedEmployee.masterdata.fullName }}</b> rückgängig machen
                          </md-tooltip>
                        </md-button>
                        <md-card-actions style="padding-bottom: 0">
                          <div v-if="selectedEmployee && editActive" class="cell text-center">
                            <button v-if="dialogMode === 'update'" class="button success" style="margin-bottom: 0"
                                    type="submit" :disabled="sending">
                              <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                            </button>
                          </div>
                        </md-card-actions>
                      </div>
                    </div>
                    <EmployeeTabs v-if="selectedEmployee && allowedFacilities"
                                  ref="employeeTabsForm"
                                  @updateEmployeeContractDocument="updateEmployeeContractDocument"
                                  :selectedEmployee="selectedEmployee"
                                  :availableFacilities="allowedFacilities"
                                  :editActive="editActive"
                                  :sending="sending">
                    </EmployeeTabs>
                  </md-tab>
                  <md-tab id="EMPLOYEE_WORKING_TIMES" md-label="Arbeitszeiten" md-icon="update" :md-template-data="{index: 1}" style="padding: 0; margin-top: 0.5rem;">
                    <md-card-content v-if="selectedEmployee" style="height: 100%">
                      <WorkingTimesView :employee="selectedEmployee" :groups="groups" @updateEmployees="updateEmployees"/>
                    </md-card-content>
                  </md-tab>
                </md-tabs>
              </md-card-content>
              <md-card-content v-else>
                <h5><i>Bitte wählen Sie einen Mitarbeiter in der Liste aus</i></h5>
              </md-card-content>

            </md-card>
          </form>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteEmployeeDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Datensatz für <span
              class="employee-title">{{lastEmployee}}</span>
            löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll der Datensatz für Mitarbeiter <span class="employee-title">{{lastEmployee}}</span> wirklich
              gelöscht
              werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
          </div>
          <div class="cell text-center">
            <button @click="onDeleteEmployee" class="button alert" :disabled="sending || !absolutelySure">
              <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Datensatz jetzt löschen
            </button>
            <button class="button success" style="margin-left: 1rem;"
                    @click="closeConfirmationDialog"
                    type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteEmployeeDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingEmployeeDataDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><img width="25px" src="../assets/icons/employee-torso.png"/>&nbsp;&nbsp;&nbsp;Unvollständigen
            Datensatz für Mitarbeiter bestätigen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll der unvollständige Datensatz für Mitarbeiter <span
                class="employee-title">{{lastEmployee}}</span>
              wirklich gespeichert werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <div @click="updateEmployee" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</div>
            <button class="button alert" style="margin-left: 1rem;" @click="confirmMissingEmployeeDataDialog = false"
                    type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="confirmMissingEmployeeDataDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteEmployeeImageDialog" class="delete-employee-image-dialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <ImageView :imageId="selected.imageId" size="tiny"></ImageView>&nbsp;&nbsp;&nbsp;Bild von <span class="employee-title">{{lastEmployee}}</span> löschen
          </md-dialog-title>
          <div class="cell">
            <md-content class="text-center">Soll das Bild von <span class="employee-title">{{lastEmployee}}</span> wirklich gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="onDeleteEmployeeImage" class="button alert" :disabled="sending">
              <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Bild jetzt löschen
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteEmployeeImageDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmSaveChangedDataDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Änderungen für Mitarbeiter bestätigen
          </md-dialog-title>
          <div class="cell">
            <md-content>Es wurden Änderungen am Datensatz für Mitarbeiter<span v-if="selectedEmployee.masterdata.salutation === 'MS'">in</span>
              &nbsp;<span class="employee-title">{{lastEmployee}}</span>
              durchgeführt. Sollen diese gespeichert werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="updateEmployee" class="button alert" :disabled="sending"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</button>
            <button class="button success" style="margin-left: 1rem;"
                    @click="onCancelSaveChanges"
                    type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="confirmSaveChangedDataDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showArchiveEmployeeDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Datensatz für <span
              class="group-title">{{ lastEmployee }}</span>&nbsp;
            <span v-if="!archiveActive">archivieren</span><span v-else>wiederherstellen</span>
          </md-dialog-title>
          <div class="cell text-center">
          <span v-if="!archiveActive">
            <b style="color: orangered; font-size: larger;">ACHTUNG</b> Für archivierte Mitarbeiter erfolgt keine weitere Meldung an das KiBiG-Web!<br><br>
          </span>
            <md-content>Soll der Datensatz für den Mitarbeiter <span class="group-title">{{ lastEmployee }}</span> wirklich <span
                v-if="!archiveActive">archiviert</span><span v-else>wiederherstellt</span>
              werden?
            </md-content>
          </div>
          <div class="cell text-center">
            <md-checkbox v-model="confirmArchive" class="md-primary"><span v-if="!archiveActive">Archivierung</span><span
                v-else>Wiederherstellung</span> bestätigen
            </md-checkbox>
            <br><br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
            <br>
            <button @click="onArchiveEmployee" class="button archive" :disabled="!confirmArchive || sending">
              <i v-if="!archiveActive" class="fi-archive"></i><i v-else class="fi-arrow-left"></i>
              &nbsp;&nbsp;&nbsp;Ja, Datensatz jetzt <span v-if="!archiveActive">archivieren</span><span v-else>wiederherstellen</span>
            </button>
            <button class="button success" style="margin-left: 1rem;" @click="showArchiveEmployeeDialog = false"
                    type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showArchiveEmployeeDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditEmployeeDialog v-if="allowedFacilities"
                          :showDialog="showEditEmployeeDialog" :employee="newEmployee" :mode="dialogMode"
                          :availableFacilities="allowedFacilities"
                          @updateEmployeeSuccess="updateEmployeeSuccess"
                          @confirmMissingData="confirmMissingData"
                          @closeEditEmployeeDialog="showEditEmployeeDialog = false"></EditEmployeeDialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="EMPLOYEE" moduleFunction="Mitarbeiter"></NoModuleCard>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import heic2any from 'heic2any';
  import {imageProcessor, resize, sharpen} from 'ts-image-processor';
  import b64toBlob from 'b64-to-blob';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import Vue from 'vue';
  import Spinner from 'vue-simple-spinner';

  import EmployeeService from '../services/EmployeeService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import HttpInterceptor from '../services/HttpInterceptor';

  import AddressView from '../components/views/AddressView';
  import ContactView from '../components/views/ContactView';
  import PersonView from '../components/views/PersonView';
  import ChildView from '../components/views/ChildView';
  import WorkingTimesView from '../components/views/WorkingTimesView';
  import ImageView from '../components/views/ImageView';

  import AlertIcons from '../components/animations/AlertIcons';

  import EmployeeTabs from '../components/tabs/EmployeeTabs';

  import Employee from '../entities/Employee';
  import Person from '../entities/Person';
  import Address from '../entities/Address';

  import EditEmployeeDialog from '../components/dialogs/EditEmployeeDialog';

  import {validationMixin} from 'vuelidate';
  import {Md5} from 'ts-md5/dist/md5';
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import NoModuleCard from '../components/cards/NoModuleCard';
  import GroupService from '../services/GroupService';
  import BinaryService from '@/services/BinaryService';

  const toLower = text => {
    if (text) {
      return text.toString().toLowerCase();
    }
    return '';
  };

  const searchByLastname = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.masterdata.lastname).includes(toLower(term)));
    }

    return items;
  };

  const searchByDivision = (items, terms) => {
    if (terms) {
      return items.filter(item => {
        for (let term of terms) {
          if (!term) {
            return true;
          }
          if (toLower(item.divisionType).includes(toLower(term))) {
            return true;
          }
        }
        return false;
      });
    }

    return items;
  };

  const searchByQualification = (items, terms) => {
    if (terms) {
      if (terms.length <= 0) {
        return items;
      }

      return items.filter(item => {
        for (let term of terms) {
          if (!term) {
            return true;
          }
          if (toLower(item.qualification).includes(toLower(term))) {
            return true;
          }
        }
        return false;
      });
    }

    return items;
  };

  const filterEmployees = (items, selectedFilter) => {
    return items.filter(item => {
      if (selectedFilter.includes('CURRENT') && selectedFilter.includes('FUTURE')) {
        return true;
      } else if (selectedFilter.includes('CURRENT') && !selectedFilter.includes('FUTURE')) {
        return !Vue.moment().isBefore(item.entryDate);
      } else if (!selectedFilter.includes('CURRENT') && selectedFilter.includes('FUTURE')) {
        return Vue.moment().isBefore(item.entryDate);
      } else {
        return (selectedFilter.length <= 0);
      }
    });
  };

  const searchByArchivingDate = (items, years) => {
    return items.filter(item => {
      let exitYear = Vue.moment(item.archivingDate, 'YYYY-MM-DD').format("YYYY");
      return years.includes(exitYear);
    });
  };

  export default {
    name: 'Employees',
    mixins: [validationMixin],

    components: {
      NoFacilityCard,
      NoModuleCard,
      ImageView,
      vueDropzone: vue2Dropzone,
      EditEmployeeDialog,
      AddressView,
      ContactView,
      PersonView,
      ChildView,
      WorkingTimesView,
      AlertIcons,
      EmployeeTabs,
      Employee,
      Person,
      Address,
      'vue-simple-spinner': Spinner
    },

    mounted() {
      if (this.$route.params['id']) {
        localStorage.setItem(this.user.md5 + '@selectedEmployeeRowId', this.$route.params['id']);
        localStorage.setItem(this.user.md5 + '@openEmployeeFromUrl', this.$route.params['id']);
      } else {
        localStorage.removeItem(this.user.md5 + '@openEmployeeFromUrl');
      }
      
      this.restoreUserSettings();
      if (!this.isDeleted) {
        this.reloadEmployees();
      }

      window.addEventListener('resize', (event) => {
        this.resizeDialogElements(event);
      }, { passive: true });

      HttpErrorHandler.maintainDarkMode(this);
    },

    data() {
      return {
        sending: false,
        groups: [],
        employees: [],
        archivedEmployees: [],
        searched: [],
        search: null,
        selected: null,
        selectedRows: [],
        showDeleteEmployeeDialog: false,
        showDeleteEmployeeImageDialog: false,
        showEditEmployeeDialog: false,
        showArchiveEmployeeDialog: false,
        confirmArchive: false,
        confirmMissingEmployeeDataDialog: false,
        confirmSaveChangedDataDialog: false,
        lastEmployee: '',
        dialogMode: 'update',
        selectedEmployee: null,
        selectedEmployeeOriginal: null,
        selectedEmployeeMd5Hash: null,

        newEmployee: null,
        editActive: false,
        editActiveSwitch: false,
        availableFacilities: [],
        allowedFacilities: [],
        selectedFacilityReferenceNumber: '',
        selectedFacilityName: '',
        selectedDivisions: [],
        selectedQualifications: [],

        selectedFilter: [],
        filters: [
          {name: 'zukünftige Mitarbeiter', id: 'FUTURE'},
          {name: 'aktuelle Mitarbeiter', id: 'CURRENT'},
        ],

        options: {
          url: '',
          maxFilesize: 20.0,
          headers: {
            'accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
          },
          thumbnailWidth: 155,
          thumbnailHeight: 155,
          /*  previewTemplate:
              '<div class="dz-preview dz-file-preview">' +
              '  <div class="dz-details">' +
              '    <div class="dz-filename"><span data-dz-name></span></div>' +
              '    <div class="dz-size" data-dz-size></div>' +
              '    <img data-dz-thumbnail />' +
              '  </div>' +
              '  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
              '  <div class="dz-success-mark"><span>✔</span></div>' +
              '  <div class="dz-error-mark"><span>✘</span></div>' +
              '  <div class="dz-error-message"><span data-dz-errormessage></span></div>' +
              '</div>',*/

          dictDefaultMessage: 'Bild zum Hochladen hier fallen lassen oder zum Auswählen hier Klicken<br><br>(max. Größe 20MB)',
          acceptedFiles: '.jpg, .jpeg, .heic, .avif, .png',
          autoProcessQueue: false,
          autoQueue: false,
        },

        togglingEditMode: false,
        togglingAddMode: false,
        selectedRowId: 0,
        activeTabId: '',
        initialTableSortDone: false,

        archiveActive: false,
        archiveActiveToggle: false,

        selectedYears: [],
        archiveYears: [],
        selectedMonths: [],

        absolutelySure: false,
      }
    },

    methods: {

      absenceStyle(absent) {
        return absent ? 'font-style: italic; color: grey;' : '';
      },

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        this.archiveActive = (localStorage.getItem(this.user.md5 + '@archiveActive') === 'true');
        this.archiveActiveToggle = this.archiveActive;

        if (this.selectedFacilityReferenceNumber === null || this.selectedFacilityReferenceNumber === 'null') {
          this.selectedFacilityReferenceNumber = '';
        }
      },

      restoreDialogSelections() {
        if (localStorage.getItem(this.user.md5 + '@selectedEmployeeRowId')) {
          this.selectedRowId = localStorage.getItem(this.user.md5 + '@selectedEmployeeRowId');
        }

        if (this.selectedRowId !== null && this.selectedRowId !== 0) {
          let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
          const retryIntervalId = setInterval(() => {

            if (this.selectedRowId === 0) {
              maxTries = 0;
              clearInterval(retryIntervalId);
            }

            let row = jQuery('#' + this.selectedRowId);
            if (row && row.length > 0) {
              clearInterval(retryIntervalId);
              row.trigger('click');
            } else {
              maxTries--;
              if (maxTries <= 0) {
                clearInterval(retryIntervalId);
              }
            }
          }, 250);
        }
      },
      
      initData() {
        if (localStorage.getItem(this.user.md5 + '@selectedDivisions')) {
          this.selectedDivisions = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedDivisions'));
        }

        if (localStorage.getItem(this.user.md5 + '@selectedQualifications')) {
          this.selectedQualifications = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedQualifications'));
        }

        this.selectedFilter = ['CURRENT'];
        if (localStorage.getItem(this.user.md5 + '@selectedFilterEmployee')) {
          this.selectedFilter = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedFilterEmployee'));
        }
        if (this.selectedFilter.includes('ARCHIVED')) {
          this.selectedFilter = [];
        }

        if (this.labels && this.labels.divisiontype && this.selectedDivisions.length <= 0) {
          this.selectedDivisions = [];
          Object.keys(this.labels.divisiontype).forEach(key => {
            this.selectedDivisions.push(key);
          });
        }

        if (this.archiveYears.length <= 0) {
          for (let i = 0; i < 11; i++) {
            let year = Vue.moment('01-01-' + String(Number(Vue.moment().format('YYYY')) - i), 'DD-MM-YYYY').format('YYYY');
            this.archiveYears.push(year);
          }
        }

        if (this.selectedYears.length <= 0) {
          for (let i = 0; i < 11; i++) {
            let year = Vue.moment('01-01-' + String(Number(Vue.moment().format('YYYY')) - i), 'DD-MM-YYYY').format('YYYY');
            this.selectedYears.push(year);
          }
        }
      },

      onShowAllEmployees() {

        if (!this.archiveActive) {
          this.selectedFilter = [];
        } else {
          this.selectedYears = this.archiveYears;
          for (let month of this.$material.locale.months) {
            this.selectedMonths.push(month);
          }
        }

        this.selectedQualifications = [];
        this.selectedDivisions = [ "HORT", "UNDEFINED", "KINDERGARTEN", "KRIPPE" ];
        this.search = '';
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
        }

        this.allowedFacilities = [];
        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber !== '*') {
            this.allowedFacilities.push(facility);
          }
        }

        this.initData();
      },

      reloadEmployees() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isSage) {
              this.getAllEmployees();
            } else if (!this.isAdmin) {
              if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
                this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
              }
            }

            this.buildAvailableFacilities();
            this.onSelectFacility();

            clearInterval(reloadIntervalId);
            jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 675px');

            this.restoreDialogSelections();
            
          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getAllEmployees() {
        this.sending = true;
        EmployeeService.getAllEmployees()
          .then(response => {
            this.employees = response.data;
            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Mitarbeiter für Träger ' + this.organization.name);
            this.sending = false;
          })
      },

      getAllOrganizationGroups() {
        this.sending = true;
        GroupService.getAllOrganizationGroups()
            .then(response => {
              this.groups = response.data;
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Gruppen');
              this.sending = false;
            })
      },

      getAllFacilityGroups(facilityReferenceNo) {
        this.sending = true;
        GroupService.getAllFacilityGroups(facilityReferenceNo)
          .then(response => {
            this.groups = response.data;
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Gruppen für Einrichtung mit Nummer ' + facilityReferenceNo);
            this.sending = false;
          })
      },

      getAllOrganizationEmployees() {
        this.sending = true;
        EmployeeService.getAllOrganizationEmployees()
          .then(response => {
            this.employees = response.data;
            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Mitarbeiter für Träger ' + this.organization.name);
            this.sending = false;
          })
      },

      getAllOrganizationFacilityEmployees(referenceNumber) {
        this.sending = true;
        EmployeeService.getAllOrganizationFacilityEmployees(referenceNumber)
          .then(response => {
            this.employees = response.data;
            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Mitarbeiter für Einrichtung mit Nummer ' + referenceNumber);
            this.sending = false;
          })
      },

      getAllArchivedOrganizationEmployees() {
        this.sending = true;
        EmployeeService.getAllArchivedOrganizationEmployees()
          .then(response => {
            this.archivedEmployees = response.data;
            this.sending = false;
            this.searchOnTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der archivierten Mitarbeiter für Träger ' + this.organization.name);
            this.sending = false;
          })
      },

      getAllArchivedOrganizationFacilityEmployees(referenceNumber) {
        this.sending = true;
        EmployeeService.getAllArchivedOrganizationFacilityEmployees(referenceNumber)
          .then(response => {
            this.archivedEmployees = response.data;
            this.sending = false;
            this.searchOnTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der archivierten Mitarbeiter für Träger ' + this.organization.name + ' und Einrichtung mit RefNr ' + referenceNumber);
            this.sending = false;
          })
      },

      updateEmployee() {
        if (this.selectedEmployee && !this.selectedEmployee.archived && this.canWrite) {
          this.sending = true;
          
          EmployeeService.updateEmployee(this.selectedEmployee)
            .then(response => {
              this.selected = response.data;
              this.selectedEmployee = response.data;
              this.sending = false;
              this.updateEmployeeSuccess();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Mitarbeiter '
                + this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname);
              this.sending = false;
            })
        }
      },

      updateEmployeeSuccess(newEmployee) {
        this.confirmMissingEmployeeDataDialog = false;
        this.confirmSaveChangedDataDialog = false;

        if (this.dialogMode === 'add') {
          this.newEmployee = JSON.parse(JSON.stringify(newEmployee));
          this.lastEmployee = this.newEmployee.masterdata.firstname + ' ' + this.newEmployee.masterdata.lastname;
          this.$store.commit('successMsg', 'Neuer Mitarbeiter &nbsp;<b>' + this.lastEmployee + '</b>&nbsp;wurde erfolgreich hinzugefügt.');
          this.employees.push(this.newEmployee);
          this.search = null;
          this.selectedRowId = newEmployee.id;
          this.updateEmployees(this.newEmployee);
        }

        if (this.dialogMode === 'update') {
          this.lastEmployee = this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname;
          this.$store.commit('successMsg', 'Die Änderungen für Mitarbeiter&nbsp;<b>' + this.lastEmployee + '</b>&nbsp;wurden erfolgreich gespeichert!');
          jQuery('#editActiveSwitchId').parent().trigger('click');
          this.updateEmployees(this.selectedEmployee);
        }

        if (this.$refs.employeeTabsForm) {
          this.$refs.employeeTabsForm.reset();
        }
      },

      updateEmployees(updatedEmployee) {
        if (updatedEmployee.facilityReference !== this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '*') {
          this.onSelectFacility();
          jQuery('#overlayLeft').removeClass('md-overlay');
        } else {
          this.employees.forEach((employee, i) => {
            if (updatedEmployee.id === employee.id) {
              this.employees[i] = JSON.parse(JSON.stringify(updatedEmployee));
            }
          })

          this.searchOnTable();
          setTimeout(() => jQuery('#' + this.selectedRowId).trigger('click'), 250);
        }
      },

      onAddEmployee() {
        this.selected = null;
        this.selectedEmployee = null;
        this.editActive = false;
        this.editActiveSwitch = false;
        this.togglingAddMode = false;

        this.newEmployee = JSON.parse(JSON.stringify(Employee.employee));
        this.newEmployee = this.completeEmployeeData(this.newEmployee);
        this.newEmployee = JSON.parse(JSON.stringify(this.newEmployee));
        if (this.allowedFacilities.length === 1) {
          this.newEmployee.facilityReference = this.allowedFacilities[0].referenceNumber;
        }
        this.onToggleAddData(false);

        this.dialogMode = 'add';
        setTimeout(() => this.showEditEmployeeDialog = true, 200);
        setTimeout(() => this.selectedRows = [], 250);
      },

      onArchiveEmployeeDialog() {
        this.confirmArchive = false;
        if (this.selected) {
          this.lastEmployee = this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname;
          this.showArchiveEmployeeDialog = true;
        }
      },

      onArchiveEmployee() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          EmployeeService.toggleArchiveState(this.selected.id)
              .then(() => {
                this.sending = false;
                if (this.archiveActive) {
                  this.$store.commit('successMsg', 'Der Datensatz für Mitarbeiter &nbsp;<b>' + this.lastEmployee + '</b>&nbsp;wurde erfolgreich wiederherstellt.');
                } else {
                  this.$store.commit('successMsg', 'Der Datensatz für Mitarbeiter &nbsp;<b>' + this.lastEmployee + '</b>&nbsp;wurde erfolgreich archiviert.');
                }
                this.success = true;
                this.search = null;
                this.selected = null;
                this.selectedEmployee = null;
                this.showArchiveEmployeeDialog = false;
                this.reloadEmployees();
              })
              .catch(e => {
                HttpErrorHandler.handleError(e, this, 'Fehler beim Archivieren des Datensatz für Mitarbeiter : '
                    + this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname);
                this.sending = false;
              })
        }
      },
      
      onDeleteEmployeeDialog() {
        if (this.selected) {
          this.lastEmployee = this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname;
          this.absolutelySure = false,
          this.showDeleteEmployeeDialog = true;
        }
      },

      onDeleteEmployee() {
        if (this.selected) {

          this.sending = true;
          EmployeeService.deleteEmployee(this.selected.id)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Datensatz für Mitarbeiter &nbsp;<b>' + this.lastEmployee + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.search = null;
              this.selected = null;
              this.selectedEmployee = null;
              this.showDeleteEmployeeDialog = false;
              this.reloadEmployees();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Datensatz für Mitarbeiter '
                + this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname);
              this.sending = false;
            })
        }
      },

      updateWorkingTimesTooltips() {
        for (let employee of this.employees) {
          employee.divisionTypeLabel = this.labels.divisiontype[employee.divisiontype];
          employee.workingTimesTooltip = '';
          if (employee.workingTimes && employee.workingTimes.length > 0) {
            employee.workingTimes.forEach(workingTime => {
              employee.workingTimesTooltip += '<i>von: <b>' + Vue.moment(workingTime.validFrom).format('DD.MM.YYYY') + '</b> bis <b>' + Vue.moment(workingTime.validUntil).format('DD.MM.YYYY') + '</b></i><br>';
              employee.workingTimesTooltip += '&nbsp;&nbsp;&nbsp;<b>' + workingTime.hoursPerWeek + 'h</b> ';
              if (workingTime.workingAreaOne) {
                employee.workingTimesTooltip += 'Arbeitsbereich ';
              }
              else {
                employee.workingTimesTooltip += "<span style='color: greenyellow;'><b>2.</b> Arbeitsbereich </span>";
              }
              employee.workingTimesTooltip += '<b>' + this.labels.workingArea[workingTime.workingArea] + '</b> ';
              employee.workingTimesTooltip += 'in Einrichtung <b>' + this.getFacilityName(workingTime.facilityRefNr) + '</b><br>';
            });
          }
        }
      },

      getFacilityName(referenceNumber) {
        if (referenceNumber && referenceNumber.length > 0 && this.organization.facilities) {
          for (let facility of this.organization.facilities) {
            if (facility.referenceNumber === referenceNumber) {
              return facility.name;
            }
          }
        }
        return '';
      },

      searchOnTable() {
        this.updateWorkingTimesTooltips();

        if (this.archiveActive) {
          this.searched = this.archivedEmployees;
        } else {
          this.searched = filterEmployees(this.employees, this.selectedFilter);
        }

        this.searched = searchByDivision(this.searched, this.selectedDivisions);
        this.searched = searchByQualification(this.searched, this.selectedQualifications);
        this.searched = searchByLastname(this.searched, this.search);


        if (this.archiveActive) {
          this.searched = searchByArchivingDate(this.searched, this.selectedYears);
        }

        this.resizeDialogElements();

        if (!this.initialTableSortDone && this.$refs.employeesTable) {
          this.initialTableSortDone = true;
          setTimeout(() => {
            this.$refs.employeesTable.sortTable();
          }, 600);
        }

        this.preparePrintTableList();
        this.preparePrintTableFilter();

        jQuery('table').removeAttr('style');
      },

      resizeDialogElements() {
        // Table header
        setTimeout(() => jQuery('.md-table-head-label').attr('style', 'padding: 0px 0px 0px 24px;'), 10);
        setTimeout(() => jQuery('table').first().width(jQuery('.md-card.md-table').width()), 20);

        // Table rows
        setTimeout(() => jQuery('.md-table-cell-container').attr('style', 'padding: 6px 0px 6px 10px;'), 30);
        setTimeout(() => jQuery('.md-table-cell').attr('style', 'padding: 6px 0px 6px 0px;'), 40);
        setTimeout(() => jQuery('.md-content.md-table-content.md-scrollbar').width(jQuery('.md-card.md-table').width()), 50);
      },

      preparePrintTableList() {
        let list = [];

        let i = 1;
        for (let item of this.searched) {

          let firstMeaslesVaccinationDate = this.booleanText(true) + ' ' + Vue.moment(item.firstMeaslesVaccinationDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
          if (firstMeaslesVaccinationDate.toLowerCase().includes('invalid date')) {
            firstMeaslesVaccinationDate = this.booleanText(false);
          }

          let secondMeaslesVaccinationDate = this.booleanText(true) + ' ' + Vue.moment(item.secondMeaslesVaccinationDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
          if (secondMeaslesVaccinationDate.toLowerCase().includes('invalid date')) {
            secondMeaslesVaccinationDate = this.booleanText(false);
          }

          list.push({
            '_Nr': i++,
            '_Anrede': this.labels.salutation[item.masterdata.salutation],
            'Vorname': item.masterdata.firstname,
            'Nachname': item.masterdata.lastname,
            '_Geburtstag': item.masterdata.birthdate !== null ? Vue.moment(item.masterdata.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
            'Alter': item.ageTextual,
            '_kibigId': item.kibigId,
            '_kibigName': item.kibigName,

            '>Adresse': true,
            '_Straße': item.masterdata.address.street,
            '_Hausnummer': item.masterdata.address.streetNumber,
            '_PLZ': item.masterdata.address.zipcode,
            '_Ort': item.masterdata.address.placeOfResidence,

            '>Kontakt': true,
            'Email': item.masterdata.contact.email,
            'Tel (Mobil)': item.masterdata.contact.mobilePhone,
            'Tel (Zuhause)': item.masterdata.contact.phone,
            '_Tel (Arbeit)': item.masterdata.contact.businessPhone,
            '_Fax': item.masterdata.contact.fax,

            '>Arbeitnehmer': true,
            '_Einrichtung': this.getFacilityName(item.facilityReference),
            '_Sozialversicherungs-Nr': item.socialSecurityNumber,
            '_Krankenversicherung': item.healthInsurance,
            '_Monatliches Gehalt': Vue.filter('currency')(item.monthlySalary),
            '>Vertrag': true,
            'Vertragsbeginn': item.contractStart !== null ? Vue.moment(item.contractStart, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
            'Vertragsende': item.contractEnd !== null ? Vue.moment(item.contractEnd, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
            '_Befristet bis': item.limitedUntil !== null ? Vue.moment(item.limitedUntil, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
            '>Meldedaten': true,
            'Bereich': this.labels.divisiontype[item.divisionType],
            'Arbeitsbereich': this.labels.workingArea[item.workingArea],
            '_2. Bereich': this.labels.divisiontype[item.secondDivisionType],
            '_2. Arbeitsbereich': this.labels.workingArea[item.secondWorkingArea],
            'Beruf': this.labels.professionalQualification[item.professionalQualification],
            '_Qualifikation': this.labels.qualification[item.qualification],
            '_Typ': this.labels.employeeType[item.employeeType],
            '_Position': this.labels.jobPosition[item.jobPosition],
          })
        }
        this.$store.commit('printTableList', list);
        this.$store.commit('printTableType', 'Employees');
      },

      booleanText(value) {
        return value ? '<span style="color: forestgreen">&#10003;</span>' : '<span style="color: red">&#10005;</span>';
      },

      getPersonName(child, personId) {
        let personName = '';
        child.relatedPersons.forEach(person => {
          if (person.id === personId) {
            personName = person.fullName;
          }
        });
        return personName;
      },

      preparePrintTableFilter() {
        let filters = {};
        filters['divisions'] = this.selectedDivisions;

        filters['groups'] = null;

        let selectedFacilityNames = [];
        for (const facility of this.availableFacilities) {
          if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
            selectedFacilityNames.push(facility.name);
          }
        }
        filters['facilities'] = selectedFacilityNames;

        let selectedFilterNames = [];
        for (const id of this.selectedFilter) {
          for (const filter of this.filters) {
            if (filter.id === id) {
              selectedFilterNames.push(filter.name);
            }
          }
        }

        filters['filters'] = selectedFilterNames;

        this.$store.commit('printTableFilters', filters);
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      completeEmployeeData(employee) {
        if (employee !== null) {
          if (employee.masterdata.address === null) {
            employee.masterdata.address = JSON.parse(JSON.stringify(Address.address));
          }

          for (let facility of this.allowedFacilities) {
            if (facility.referenceNumber === employee.facilityReference) {
              employee.facilityName = facility.name;
            }
          }

          return employee;
        }
      },

      onSelect(item) {
        if (item && item.id) {
          this.selectedRowId = item.id;
          this.dialogMode = 'update';
          this.selected = JSON.parse(JSON.stringify(item));
          this.selected = this.completeEmployeeData(this.selected);
          this.selectedEmployee = JSON.parse(JSON.stringify(this.selected));

          // This is a hack to fix a server bug resulting from DB change without DBRef
          this.selectedEmployee.masterdata.id = this.selectedEmployee.id;

          this.options.url = '';
          setTimeout(() => this.options.url = this.onVdropzoneUpload, 500)
        } else {
          this.selected = null;
          this.selectedEmployee = null;

          if (this.selectedRowId) {
            if (this.selectedRowId === localStorage.getItem(this.user.md5 + '@openEmployeeFromUrl')) {
              jQuery('#' + this.selectedRowId).trigger('click');
            }

            this.selectedRowId = 0;
          }
        }
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber',this.selectedFacilityReferenceNumber);

          this.selectedFacilityName = '';
          this.selectedFacility = null;
          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = facility.name;
              this.selectedFacility = facility;
            }
          }

          if (this.editActive) {
            jQuery('#editActiveSwitchId').parent().trigger('click')
          }

          this.selected = null;
          this.selectedEmployee = null;

          if (this.selectedFacilityReferenceNumber === '*') {
            this.getAllOrganizationEmployees();
            this.getAllArchivedOrganizationEmployees();
            this.getAllOrganizationGroups();
          } else {
            this.getAllOrganizationFacilityEmployees(this.selectedFacilityReferenceNumber);
            this.getAllArchivedOrganizationFacilityEmployees(this.selectedFacilityReferenceNumber);
            this.getAllFacilityGroups(this.selectedFacilityReferenceNumber);
          }
        }
      },

      onSelectDivision() {
        localStorage.setItem(this.user.md5 + '@selectedDivisions', JSON.stringify(this.selectedDivisions));
        this.searchOnTable();
      },

      onSelectQualification() {
        localStorage.setItem(this.user.md5 + '@selectedQualifications', JSON.stringify(this.selectedQualifications));
        this.searchOnTable();
      },

      onSelectFilter() {
        localStorage.setItem(this.user.md5 + '@selectedFilterEmployee', JSON.stringify(this.selectedFilter));
        this.searchOnTable();
      },

      onToggleArchive() {
        this.onSelect(null);

        setTimeout(() => {
          this.archiveActive = !this.archiveActive;
          localStorage.setItem(this.user.md5 + '@archiveActive', this.archiveActive);
          this.searchOnTable();
        }, 300);
      },

      validateEmployee() {
        if (this.dialogMode === 'update') {
          this.$refs.employeeTabsForm.validateEmployee();
          if (!this.$refs.employeeTabsForm.invalid) {
            this.updateEmployee();
          } else {
            this.confirmMissingData();
          }
        }
      },

      confirmMissingData() {
        this.lastEmployee = this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname;
        this.confirmMissingEmployeeDataDialog = true;
      },

      onTouchOverlay() {
        this.$refs.employeeTabsForm.$refs.employeeForm.validateEmployee();

        setTimeout(() => {
          if (this.selectedEmployeeMd5Hash !== Md5.hashAsciiStr(JSON.stringify(this.selectedEmployee))) {
            jQuery('span:contains("Stamm-Daten")').trigger('click');
            this.lastEmployee = this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname;
            this.confirmSaveChangedDataDialog = true;
          } else {
            jQuery('#editActiveSwitchId').parent().trigger('click');
            this.onTabsChanged(this.activeTabId);
          }
        }, 250);
      },

      onCancelSaveChanges() {
        this.confirmSaveChangedDataDialog = false;
        this.selectedEmployee = JSON.parse(JSON.stringify(this.selectedEmployeeOriginal));
        jQuery('#editActiveSwitchId').parent().trigger('click');
      },

      onToggleEditData(activateEditor) {
        if (!this.editActive && !this.togglingEditMode) {
          this.togglingEditMode = true;
          setTimeout(() => {
            this.togglingEditMode = false;
          }, 500);
        }

        if (activateEditor) {
          this.selectedEmployeeMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedEmployee));
          this.selectedEmployeeOriginal = JSON.parse(JSON.stringify(this.selectedEmployee));
        }

        setTimeout(() => this.editActive = activateEditor, 10);
        jQuery('#overlayLeft').toggleClass('md-overlay');
      },

      onToggleAddData(activateEditor) {
        if (!this.editActive && !this.togglingAddMode) {
          this.togglingAddMode = true;
          setTimeout(() => {
            this.togglingAddMode = false;
          }, 2000);
        }
        setTimeout(() => this.editActive = activateEditor, 10);
      },

      onVdropzoneUpload() {
        return '';
      },

      onVdropzoneFileAdded(file) {
        this.sending = true;
        this.$refs.employeeImageDropzone.removeAllFiles(true);
        this.convertImage(file);
      },

      convertImage(file) {
        heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.1,
        }).then((conversionResult) => {
          this.processImage(file, conversionResult, this);
        }).catch((e) => {
          console.log('convertImage error : ' + JSON.stringify(e));
          this.processImage(file, file, this);
        });
      },

      processImage(file, blob, instance) {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          imageProcessor
              .src(base64data)
              .pipe(
                  resize({
                    maxWidth: 500,
                    maxHeight: 500,
                  }),
                  sharpen({
                    sharpness: 50 / 100,
                  }),
              ).then(resultBase64 => {
            resultBase64 = resultBase64.substring(resultBase64.indexOf('base64,') + 7);
            const resultBlob = b64toBlob(resultBase64, 'image/jpeg');
            instance.uploadImage(instance.selected.id, file, resultBlob);
          });
        }
      },

      uploadImage(employeeId, file, blob) {
        this.sending = true;
        BinaryService.uploadEmployeeImage(employeeId, file, blob)
            .then(response => {
              this.onVdropzoneSuccess(file, response.data);
            })
            .catch(e => {
              this.sending = false;
              HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern des Bildes <b>' + file.upload.filename + '</b>');
            })
      },

      onVdropzoneClick() {
        HttpInterceptor.doRefreshToken();
        this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
      },

      onVdropzoneProcessing() {
        HttpInterceptor.doRefreshToken();
        this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
      },

      onVdropzoneError(file, message) {
        let errMsg = 'Fehler beim Laden der Datei: <b>' + file.upload.filename + '</b><br>';
        if (message.status) {
          errMsg += '<br><b>' + message.status + '</b>';
        }
        if (message.error) {
          errMsg += ' ' + message.error;
        }
        if (message.message) {
          errMsg += '<br>' + message.message;
        }

        HttpErrorHandler.handleError(null, this, errMsg);
        this.uploading = false;

        if (message.error === 'invalid_token') {
          HttpInterceptor.forceLogout();
        }
        this.$refs.employeeImageDropzone.removeAllFiles(true);
      },

      onVdropzoneSuccess(file, response) {
        this.$store.commit('successMsg', 'Die Datei <b>' + file.upload.filename + '</b> wurde erfolgreich hochgeladen.');
        setTimeout(() => {
          this.selected = JSON.parse(JSON.stringify(response));
          this.selected = this.completeEmployeeData(this.selected);
          this.selectedEmployee = JSON.parse(JSON.stringify(this.selected));
          this.updateEmployeeImageId(this.selectedEmployee);
          this.sending = false;
        }, 500);
      },

      deleteImageDialog() {
        if (this.selected) {
          this.lastEmployee = this.selectedEmployee.masterdata.firstname + ' ' + this.selectedEmployee.masterdata.lastname;
          this.showDeleteEmployeeImageDialog = true;
        }
      },

      onDeleteEmployeeImage() {
        if (this.selected) {

          this.sending = true;
          EmployeeService.deleteImageOfEmployee(this.selected.id)
            .then(response => {
              this.showDeleteEmployeeImageDialog = false;
              this.sending = false;
              this.selected = JSON.parse(JSON.stringify(response.data));
              this.selected = this.completeEmployeeData(this.selected);
              this.selectedEmployee = JSON.parse(JSON.stringify(this.selected));
              this.updateEmployeeImageId(this.selectedEmployee);
              this.$store.commit('successMsg', 'Das Bild für Mitarbeiter &nbsp;<b>' + this.lastEmployee + '</b>&nbsp;wurde erfolgreich gelöscht.');
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Bildes von Mitarbeiter ' + this.lastEmployee);
              this.sending = false;
            })
        }
      },

      updateEmployeeImageId(updatedEmployee) {
        for (let i = 0; i < this.searched.length; i++) {
          if (updatedEmployee.id === this.searched[i].id) {
            this.searched[i].imageId = updatedEmployee.imageId;
          }
        }
        for (let i = 0; i < this.employees.length; i++) {
          if (updatedEmployee.id === this.employees[i].id) {
            this.employees[i].imageId = updatedEmployee.imageId;
          }
        }
        this.searchOnTable();
      },

      onTabsChanged(tabId) {
        this.activeTabId = tabId;
        setTimeout(() => jQuery('#employeeManagementTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%'), 50);
      },

      closeConfirmationDialog() {
        this.showDeleteEmployeeDialog = false;
      },

      updateEmployeeContractDocument(employee) {
        this.selected.documentAvailable = employee.documentAvailable;
        this.selected.documentFileName = employee.documentFileName;
        this.selected.documentId = employee.documentId;

        this.selectedEmployee.documentAvailable = employee.documentAvailable;
        this.selectedEmployee.documentFileName = employee.documentFileName;
        this.selectedEmployee.documentId = employee.documentId;

        this.employees.forEach((e, i) => {
          if (e.id === employee.id) {
            this.employees[i].documentAvailable = employee.documentAvailable;
            this.employees[i].documentFileName = employee.documentFileName;
            this.employees[i].documentId = employee.documentId;
          }
        })

        this.searched.forEach((e, i) => {
          if (e.id === employee.id) {
            this.searched[i].documentAvailable = employee.documentAvailable;
            this.searched[i].documentFileName = employee.documentFileName;
            this.searched[i].documentId = employee.documentId;
          }
        })

        this.selectedEmployeeMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedEmployee));
      }
    },

    computed: {

      fullViewClass() {
        if (this.archiveActive) {
          return 'archive-active';
        }
      },

      dataAvailable() {
        return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
            this.user.allowedFacilities.length > 0 &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },
      
      isDeleted() {
        return this.$store.getters.deleted;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isHrManager() {
        return this.$store.getters.hrmanager;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },

      labels() {
        return this.$store.getters.labels
      },

      isDevEnv() {
        return process.env.NODE_ENV === 'development';
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 800px;
  }

  .md-dialog.delete-employee-image-dialog /deep/.md-dialog-container {
    max-width: 700px !important;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .employee-title {
    color: chocolate;
    font-weight: bold;
    font-size: larger;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-tab {
    padding-left: 8px;
    padding-right: 8px;
  }

  .md-toolbar.md-theme-default.md-transparent .md-title {
    color: cornflowerblue;
    margin: 0;
  }

  .default-image {
    width: 155px;
    height: 155px;
    border-radius: 10px;
    object-fit: cover;
    opacity: 0.15;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

</style>

<style>
  .md-table-head.md-sortable:first-of-type .md-table-sortable-icon, .md-table-head.md-table-cell-selection+.md-sortable .md-table-sortable-icon {
    left: 0 !important;
  }

  .dropzone {
    padding: 0;
    width: 155px;
    height: 155px;
  }

  .dropzone .dz-message {
    text-align: center;
    margin: 1.5em 1em;
  }

  .dropzone .dz-preview {
    margin: 0;
  }

  .dropzone .dz-preview .dz-progress {
    top: 85%;
  }

  .KRIPPE {
    color: lightpink;
    background: none;
  }

  .KINDERGARTEN {
    color: lightskyblue;
    background: none;
  }

  .HORT {
    color: lightgreen;
    background: none;
  }

  .UNDEFINED {
    color: red;
    background: none;
  }
</style>
